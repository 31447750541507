import * as React from 'react';
import { settParamsContext } from '../hocs/params-context';
import { injectIntl } from 'react-intl';
import { hrefResolver } from '../utils/intl-utils';
import { Link } from 'react-router-relative-link';
import classNames from 'classnames';

const harTomtInnhold = (hrefKey, resolvedHref) => (
    hrefKey === 'void0' ||
    hrefKey === 'void 0' ||
    resolvedHref === 'void0' ||
    resolvedHref === 'void 0'
);

interface Props {
    hrefKey?: string;
    href?: string;
    children?: React.ReactNode;
    className?: string;
    intl?: any;
    'data-testid'?: string;
    params?: any;
    target?: string;
    onClick?: any;
}

const lenkepanelBase: React.FunctionComponent<Props> = (props: Props) => {
    const resolved = hrefResolver(props.href, props.hrefKey, props.intl, props.params);

    if (resolved.href.startsWith('http') || resolved.href.startsWith('/')) {
        return (
            <a href={resolved.href} target={props.target}
                className={classNames(props.className, 'lenkepanel', 'lenkepanel--border')}
                data-testid={props['data-testid']}
                onClick={props.onClick}
            >
                <div className={'typo-undertittel lenkepanel__heading'}>{props.children}</div>
                <span className="lenkepanel__indikator" />
            </a>
        );
    }

    if (harTomtInnhold(props.hrefKey, resolved.href)) {
        return null;
    }

    return (
        <Link to={resolved.href} target={props.target}
            className={classNames(props.className, 'lenkepanel', 'lenkepanel--border')}
            data-testid={props['data-testid']}
            onClick={props.onClick}
        >
            <div className={'typo-undertittel lenkepanel__heading'}>{props.children}</div>
            <span className="lenkepanel__indikator" />
        </Link>
    );
};

const LenkepanelBase = injectIntl(settParamsContext(lenkepanelBase));
export default LenkepanelBase;

export const TilpassetLenkepanel = (props) =>
    <LenkepanelBase {...props} />;
