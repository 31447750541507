import * as React from 'react';
import { Normaltekst, Systemtittel } from 'nav-frontend-typografi';
import { hentTekster } from '../../../utils/intl-utils';
import { TilpassetLenkepanel } from '../../../components/tilpasset-lenkepanel';
import SituasjonProps from '../../../utils/situasjon-props';
import andreHjelpetjenesterBilde from '../../../img/andre-hjelpetjenester-utenfor-nav.svg';
import './andre-hjelpetjenester.less';

const intlTekster = (intl) => ({
    tittel: intl.tekst('tittel'),
    ingress: intl.tekst('ingress'),
    sporsmalOmOkonomiLenkeTekst: intl.tekst('sporsmal-om-okonomi.lenke'),
    sporsmalOmOkonomiLenkeUrl: intl.key('sporsmal-om-okonomi.lenke.url'),
    trengerBostedLenkeTekst: intl.tekst('trenger-bosted.lenke'),
    trengerBostedLenkeUrl: intl.key('trenger-bosted.lenke.url'),
    andreHjelpeTjenesterUrl: intl.key('url')
});

const AndreHjelpetjenesterBolk: React.FunctionComponent<SituasjonProps> = (props: SituasjonProps) => {
    const tekster = hentTekster(intlTekster, `${props.situasjon}.andre-hjelpetjenester-bolk.`);
    return (
        <div className="andre-hjelpetjenester-bolk">
            <div className="andre-hjelpetjenester-bolk__wrapper">
                <TilpassetLenkepanel
                    className="andre-hjelpetjenester-bolk--sporsmal-om-okonomi-lenke"
                    hrefKey={tekster.sporsmalOmOkonomiLenkeUrl}
                >
                    {tekster.sporsmalOmOkonomiLenkeTekst}
                </TilpassetLenkepanel>
                <TilpassetLenkepanel
                    className="andre-hjelpetjenester-bolk--trenger-bosted-lenke"
                    hrefKey={tekster.trengerBostedLenkeUrl}
                >
                    {tekster.trengerBostedLenkeTekst}
                </TilpassetLenkepanel>
            </div>
            <TilpassetLenkepanel className="lenkepanel-med-bilde" hrefKey={tekster.andreHjelpeTjenesterUrl}>
                <img src={andreHjelpetjenesterBilde} alt="Hjelpetjenester utenfor NAV" className="lenkepanel-med-bilde__illustrasjon"/>
                <div className="lenkepanel-med-bilde__tekst">
                    <Systemtittel tag="h3" className="lenkepanel-med-bilde__overskrift">{tekster.tittel}</Systemtittel>
                    <Normaltekst tag="span" className="lenke ingress-avstand">{tekster.ingress}</Normaltekst>
                </div>
            </TilpassetLenkepanel>
        </div>
    );
};

export default AndreHjelpetjenesterBolk;
