import { fetchThenDispatch } from '../api/fetch-utils';
import {
    ActionType,
    Handling,
    HentFeatureTogglesFEILETAction,
    HentFeatureTogglesOKAction,
    HentFeatureTogglesPENDINGAction
} from './actions';
import { Dispatch } from './dispatch';
import { DataElement, Status } from '../components/datalaster';
import { getFeatureTogglesFetch } from '../api/api';

export const toggleRegistreringTekst = 'veiledearbeidssoker.registrering.tekst';

export interface FeatureToggles {
    [toggleRegistreringTekst]: boolean;
}

export type FeatureToggleState = DataElement & FeatureToggles;

export const initialState: FeatureToggleState = {
    status: Status.IKKE_STARTET,
    [toggleRegistreringTekst]: false,
};

//  Reducer
export default function reducer(state: FeatureToggleState = initialState, action: Handling): FeatureToggleState {
    switch (action.type) {
        case ActionType.HENT_FEATURE_TOGGLES_OK:
            return {
                status: Status.OK,
                [toggleRegistreringTekst]: action.featureToggles[toggleRegistreringTekst],
            };
        case ActionType.HENT_FEATURE_TOGGLES_PENDING:
            return {...state, status: Status.PENDING};
        case ActionType.HENT_FEATURE_TOGGLES_FEILET:
            return {
                ...state,
                status: Status.OK,
            };
        default:
            return state;
    }
}

export function hentFeatureToggles(): (dispatch: Dispatch) => Promise<void> {
    return fetchThenDispatch<FeatureToggles>(
        () => getFeatureTogglesFetch([toggleRegistreringTekst]), {
            ok: hentFeatureTogglesOK,
            feilet: hentFeatureTogglesFEILET,
            pending: hentFeatureTogglesPENDING,
        });
}

function hentFeatureTogglesOK(featureToggles: FeatureToggles): HentFeatureTogglesOKAction {
    return {
        type: ActionType.HENT_FEATURE_TOGGLES_OK,
        featureToggles,
    };
}

function hentFeatureTogglesFEILET(): HentFeatureTogglesFEILETAction {
    return {
        type: ActionType.HENT_FEATURE_TOGGLES_FEILET,
    };
}

function hentFeatureTogglesPENDING(): HentFeatureTogglesPENDINGAction {
    return {
        type: ActionType.HENT_FEATURE_TOGGLES_PENDING,
    };
}
