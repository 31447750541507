import * as React from 'react';
import SituasjonProps from '../../../utils/situasjon-props';
import Ekspanderbartpanel from 'nav-frontend-ekspanderbartpanel';
import { Element, Normaltekst, Systemtittel, Undertittel } from 'nav-frontend-typografi';
import './videreutdanning.less';
import Lenke from 'nav-frontend-lenker';

const Videreutdanning: React.FunctionComponent<SituasjonProps> = (props: SituasjonProps) => {
    const dagensDato = new Date();
    const fodselsAarForVoksenrett = dagensDato.getFullYear() - 24;

    return (
        <div className="videreutdanning-bolk">
            <div className="blokk-center">
                <div className="blokk-m">
                    <Systemtittel tag="h2" className="blokk-xxs">Hvordan kan du fullføre utdanningen din?</Systemtittel>

                    <Ekspanderbartpanel tittel="Videregående skole" border={true} className="blokk-s">
                        <Normaltekst className="blokk-m">Videregående legger grunnlaget for å søke videre studier, eller du kan velge yrkesfag og få fagbrev etter en periode i lære hos en bedrift. Offentlig videregående skole gir rett til stipend fra Lånekassen.</Normaltekst>

                        <Undertittel className="blokk-xxxs">Valg av linje på videregående skole</Undertittel>
                        <Normaltekst className="blokk-xxs">På vilbli.no finner du en oversikt over <Lenke href="https://www.vilbli.no/nb/nb/no" target="_blank">linjer du kan velge på videregående</Lenke>.</Normaltekst>
                        <Element>Trenger du råd?</Element>
                        <Normaltekst className="blokk-xxs"><Lenke href="http://utdanning.no/tema/hjelp_og_veiledning/karrieresentre" target="_blank">Karrieresentre</Lenke> rundt om i landet tilbyr veiledning til alle over 19 år. Veiledningen er gratis, og veilederne har taushetsplikt.</Normaltekst>
                        <Element>Bestemt deg?</Element>
                        <Normaltekst className="blokk-m">Les om hvordan du <Lenke href="https://utdanning.no/tema/soknad_og_opptak/slik_soker_du_videregaende" target="_blank">søker om opptak til videregående skole på utdanning.no</Lenke>.</Normaltekst>

                        <Undertittel className="blokk-xxxs">Videregående for deg under 25 år</Undertittel>
                        <Normaltekst className="blokk-xxs">Er du under 25 år og har ikke fullført grunnskole eller tilsvarende opplæring? Du har rett til 3 års videregående opplæring. Les mer om <Lenke href="https://www.lanekassen.no/nb-NO/Stipend-og-lan/vg/krav-og-vilkar-for-a-fa-stotte/Ungdomsrett/" target="_blank">ungdomsrett på lanekassen.no</Lenke> eller på <Lenke href="https://www.vilbli.no/nb/nb/no/ungdomsrett-lovfestet-rett-for-ungdom/a/023632/" target="_blank">vilbli.no</Lenke>.</Normaltekst>
                        <Normaltekst className="blokk-xxs">Du må bruke hele retten innen utgangen av det året du fyller 24 år.</Normaltekst>
                        <Normaltekst className="blokk-m">Du har rett til <Lenke href="https://www.vilbli.no/nb/nb/no/oppfolgingstjenesten-ot/a/029096" target="_blank">hjelp fra oppfølgingstjenesten</Lenke> frem til du er 21 år. Les mer om oppfølgingstjenesten der du bor på vilbli.no.</Normaltekst>

                        <Undertittel className="blokk-xxxs">Videregående for deg over 25 år</Undertittel>
                        <Normaltekst className="blokk-xxs">Du kan få tilbud om gratis videregående opplæring hvis du</Normaltekst>
                        <ul className="blokk-xxs">
                            <li><Normaltekst>{`er født i ${fodselsAarForVoksenrett} eller tidligere`}</Normaltekst></li>
                            <li><Normaltekst>har fullført grunnskole eller tilsvarende</Normaltekst></li>
                            <li><Normaltekst>ikke har fullført videregående opplæring i Norge eller hjemlandet ditt</Normaltekst></li>
                        </ul>

                        <Normaltekst className="blokk-m">Fylkeskommunen har ansvar for videregående opplæring. Les mer om <Lenke href="https://utdanning.no/tema/hjelp_og_veiledning/videregaende_som_voksen" target="_blank">"videregående som voksen" på utdanning.no</Lenke>.</Normaltekst>
                        <Undertittel className="blokk-xxxs">Økonomisk støtte til videregående skole</Undertittel>

                        <Element>Stipend til videregående for deg under 25 år</Element>
                        <Normaltekst className="blokk-xxs">Hos Lånekassen finner du informasjon om hvor mye du kan få i <Lenke href="https://www.lanekassen.no/nb-NO/Stipend-og-lan/vg/Sa-mye-kan-du-fa/" target="_blank">stipend eller lån når du går på videregående</Lenke>.</Normaltekst>

                        <Element>Stipend til videregående for deg over 25 år</Element>
                        <Normaltekst className="blokk-xxs">Du kan få <Lenke href="https://lanekassen.no/nb-NO/Stipend-og-lan/vg/krav-og-vilkar-for-a-fa-stotte/videregaende-opplaring-uten-ungdomsrett/" target="_blank">støtte til videregående opplæring når du er over 25 år</Lenke>. Les mer om hvordan du søker støtte på lanekassen.no.</Normaltekst>

                        <Element>Barnebidrag for barn over 18 år</Element>
                        <Normaltekst className="blokk-xxs">Hvis du ikke bor sammen med begge foreldrene dine, kan du ha krav på bidrag fra en eller begge foreldre. Les hvordan du søker om <Lenke href="https://www.nav.no/no/Person/Familie/Barne+og+ektefellebidrag/Relatert+informasjon/kan-barn-over-18-%C3%A5r-motta-barnebidrag" target="_blank">barnebidrag når du er over 18 år</Lenke>.</Normaltekst>

                        <Element>Utdanningsstønad hvis du er aleneforsørger</Element>
                        <Normaltekst className="blokk-m">Er du aleneforsørger for et barn og har rett til overgangsstønad fra NAV? Da kan du også ha rett til ekstra støtte hvis du trenger utdanning (utdanningsstønad). Les mer om <Lenke href="https://www.nav.no/no/Person/Familie/Enslig+mor+eller+far/overgangsst%C3%B8nad" target="_blank">overgangsstønad og hvordan du søker</Lenke>.</Normaltekst>

                        <Undertittel className="blokk-xxxs">Ta opp fag og forbedre karakterer</Undertittel>
                        <Normaltekst>Hvis du mangler fag eller vil forbedre karakterer fra videregående, kan du gjøre det som privatist. Les mer om å <Lenke href="https://utdanning.no/tema/soknad_og_opptak/privatist" target="_blank">ta opp fag som privatist</Lenke> på utdanning.no.</Normaltekst>
                    </Ekspanderbartpanel>
                    <Ekspanderbartpanel tittel="Hjelp til å fullføre skole og utdanning" border={true} className="blokk-s">
                        <Normaltekst className="blokk-m">Det finnes hjelp som gjør det lettere å fullføre skole og utdanning.</Normaltekst>

                        <Undertittel className="blokk-xxxs">Noen å snakke med</Undertittel>
                        <Normaltekst className="blokk-xxs">Hvis du synes det er vanskelig å følge undervisningen, bør du snakke med noen på skolen din. Du kan ha rett på tilpasset undervisning.</Normaltekst>
                        <Normaltekst className="blokk-m">Her finner du en oversikt over <Lenke href="https://veiledearbeidssoker.nav.no/ung-lite-erfaring/andre-hjelpetjenester-utenfor-nav?sprak=nb" target="_blank">andre du kan ta kontakt med</Lenke>.</Normaltekst>

                        <Undertittel className="blokk-xxxs">Hjelpemidler fra NAV</Undertittel>
                        <Normaltekst className="blokk-xxs">Har du utfordringer med språk, helse eller funksjonsnedsettelser som gjør det vanskelig å fullføre skole eller utdanning? Da kan du ha rett på tilrettelegging fra NAV. Les mer om hvilke <Lenke href="https://www.nav.no/no/Person/Hjelpemidler/Hva+har+du+vansker+med" target="_blank">hjelpemidler du kan søke om fra NAV</Lenke>.</Normaltekst>

                        <Element>Lese og skrive</Element>
                        <Normaltekst className="blokk-m">Har du problemer med å lese og skrive, kan du for eksempel søke om støtte til å kjøpe PC eller nettbrett. Les mer <Lenke href="https://www.nav.no/no/Person/Hjelpemidler/Hva+har+du+vansker+med/lese-og-skrive" target="_blank">om hjelpemidler som gjør det lettere å lese og/eller skrive</Lenke>.</Normaltekst>

                        <Undertittel className="blokk-xxxs">Økonomisk støtte når du er syk</Undertittel>
                        <Normaltekst className="blokk-xxs">Blir du syk og ikke klarer å følge undervisningen, kan du ha rett til sykepenger eller arbeidsavklaringspenger fra NAV. Studielånet ditt kan også gjøres om til stipend i perioden du er syk.</Normaltekst>

                        <Element>Studielån fra Lånekassen kan gjøres om til stipend</Element>
                        <Normaltekst className="blokk-xxs">Studielånet kan gjøres om til stipend i perioden du er syk, les mer om <Lenke href="http://www.lanekassen.no/nb-NO/Stipend-og-lan/Hoyere-utdanning/Sykdom/" target="_blank">sykestipend på lanekassen.no</Lenke>.</Normaltekst>

                        <Element>Sykepenger fra NAV</Element>
                        <Normaltekst className="blokk-xxs">Har du hatt jobb ved siden av studiet i mer enn 4 uker før du ble syk og tjent mer enn 49 929 kroner, kan du ha rett til sykepenger fra NAV. Hvor mye du får, er avhengig av arbeidsinntekt og type arbeidsforhold. Les <Lenke href="https://www.nav.no/no/Person/Arbeid/Sykmeldt%2C+arbeidsavklaringspenger+og+yrkesskade/Sykepenger/Sykepenger+til+arbeidstakere" target="_blank">mer om sykepenger</Lenke>.</Normaltekst>

                        <Element>Arbeidsavklaringspenger (AAP) fra NAV</Element>
                        <Normaltekst className="blokk-xxs">Du kan ha rett til <Lenke href="https://www.nav.no/no/Person/Arbeid/Arbeidsavklaringspenger/arbeidsavklaringspenger-aap" target="_blank">arbeidsavklaringspenger fra NAV</Lenke> hvis du er over 18 år og har avsluttet studier på grunn av sykdom eller skade, eller trenger behandling for å kunne gjenoppta studier.</Normaltekst>

                        <Normaltekst>Snakk med legen eller studiestedet ditt for mer informasjon om hva du har rett til.</Normaltekst>
                    </Ekspanderbartpanel>
                    <Ekspanderbartpanel tittel="Muligheter etter videregående skole" border={true}>
                        <Normaltekst className="blokk-m">Det er mange muligheter når du har fullført videregående skole. Her finner du noen eksempler.</Normaltekst>

                        <Undertittel className="blokk-xxxs">Folkehøgskole</Undertittel>
                        <Normaltekst className="blokk-xxs">Du kan bruke et år på folkehøgskole som forberedelse til videre utdanning. Du kan utforske interessene dine og få nye venner. Les mer om folkehøyskole på folkehoyskole.no.</Normaltekst>

                        <Element>Lånekassen</Element>
                        <Normaltekst className="blokk-m">Du kan søke om <Lenke href="https://www.lanekassen.no/nb-NO/Stipend-og-lan/Hoyere-utdanning/utdanninger-du-kan-fa-stotte-til/folkehogskole/" target="_blank"> lån og stipend fra Lånekassen til folkehøyskole</Lenke>.</Normaltekst>

                        <Undertittel className="blokk-xxxs">Forsvaret</Undertittel>
                        <Normaltekst className="blokk-m">Les om mulighetene i førstegangstjenesten og andre <Lenke href="https://forsvaret.no/karriere/utdanning" target="_blank">utdanningsmuligheter i Forsvaret på forsvaret.no</Lenke>.</Normaltekst>

                        <Undertittel className="blokk-xxxs">Fagskoler</Undertittel>
                        <Normaltekst className="blokk-m">Fagskoler er yrkesrettede utdanninger som varer mellom 1/2 - 2 år. Du kan velge mellom helsefag, reiseliv, media, økonomi med mer. Les om <Lenke href="https://utdanning.no/tema/nyttig_informasjon/fakta_om_fagskole" target="_blank">fagskole på utdanning.no</Lenke>.</Normaltekst>

                        <Undertittel className="blokk-xxxs">Høyere utdanning</Undertittel>
                        <Normaltekst className="blokk-xxs">Har du studiekompetanse eller realkompetanse, kan du søke på treårig bachelorprogram eller femårig masterprogram. Elever i videregående opplæring har rett til rådgiving om utdannings- og yrkesmuligheter. Ta kontakt med yrkesrådgiveren ved skolen din.</Normaltekst>
                        <Normaltekst className="blokk-m">Les om om hvordan du <Lenke href="https://utdanning.no/tema/soknad_og_opptak/slik_soker_du_hoyere_utdanning" target="_blank">søker på høyere utdanning på utdanning.no</Lenke>.</Normaltekst>

                        <Undertittel className="blokk-xxxs">Utdanning i utlandet</Undertittel>
                        <Normaltekst className="blokk-m">Hvis du studerer i utlandet, kan du lære om andre kulturer, bli bedre på språk og få en opplevelse for livet. Du kan ta hele eller deler av utdanningen i utlandet. <Lenke href="https://utdanning.no/tema/hjelp_og_veiledning/utdanning_i_utlandet#collapse67154112" target="_blank">Les mer på utdanning.no</Lenke>.</Normaltekst>

                        <Undertittel className="blokk-xxxs">Økonomisk støtte til utdanning</Undertittel>
                        <Normaltekst className="blokk-xxs">De fleste finansierer studiene ved studielån og ved å jobbe ved siden av.</Normaltekst>

                        <Element>Lånekassen</Element>
                        <Normaltekst className="blokk-xxs">Du kan søke om lån og stipend fra Lånekassen til høyere utdanning eller annen videreutdanning. <Lenke href="https://www.lanekassen.no/nb-NO/verktoy-og-frister/stottekalkulator_02/" target="_blank">Lånekassens støttekalkulator</Lenke> viser hvor mye du har rett på.</Normaltekst>

                        <Element>Aleneforsørgere kan ha rett til skolepenger</Element>
                        <Normaltekst className="blokk-xxs">Er du aleneforsørger kan du ha <Lenke href="https://www.nav.no/no/Person/Familie/Enslig+mor+eller+far/overgangsst%C3%B8nad" target="_blank">rett til overgangsstønad</Lenke> fra NAV. Du kan også ha rett på <Lenke href="https://www.nav.no/no/Person/Familie/Enslig+mor+eller+far/Tilleggsst%C3%B8nader+og+st%C3%B8nad+til+skolepenger" target="_blank">tilleggsstønader og stønad til skolepenger</Lenke> hvis du trenger utdanning for å bli i stand til å forsørge deg selv.</Normaltekst>

                        <Element>Andre støtteordninger</Element>
                        <Normaltekst>Det finnes fond, stipender og legater som gir støtte til utdanning. Les mer om <Lenke href="http://www.ung.no/stipend/98_Stipend,_fond_og_legater.html" target="_blank">stipend, fond og legater på ung.no</Lenke>.</Normaltekst>
                    </Ekspanderbartpanel>

                </div>
            </div>
        </div>
    );
};

export default Videreutdanning;
