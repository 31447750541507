import * as React from 'react';
import { hentTekster } from '../../utils/intl-utils';
import { LenkeHovedknapp } from '../../components/lenkeknapp';
import reiseFlyttingBilde from '../../img/stotte-til-reise-og-flytting.svg';
import stotteUtdanningBilde from '../../img/stotte-til-utdanning.svg';
import RouterProps from '../../utils/router-props';
import SidemalSenter from '../../maler/sidemal-senter';
import { BannerProps } from '../../maler/bannermal';
import { Normaltekst } from 'nav-frontend-typografi';
import './dagpenger-annenstotte.less';

const intlTekster = (intl) => ({
    bannerTittel: intl.tekst('tittel'),
    bannerIngress: intl.tekst('ingress'),
    innhold: intl.html('innhold', true),
    sokStonad: intl.tekst('sok-stonad'),
    sokStonadLenke: intl.key('sok-stonad-lenke')
});

const navn = 'dagpenger-annenstotte';

const DagpengerAnnenstotte: React.FunctionComponent<RouterProps> = (props: RouterProps) => {
    const bannerBilde = props.match.params.situasjon === 'ung-lite-erfaring' ? stotteUtdanningBilde : reiseFlyttingBilde;
    const tekster = hentTekster(intlTekster, `${props.match.params.situasjon}.${navn}.`);

    const bannerProps = {
        tittel: tekster.bannerTittel,
        ingress: tekster.bannerIngress,
        bildeSrc: bannerBilde,
    } as BannerProps;

    return (
        <SidemalSenter bannerProps={bannerProps} className={navn} sideNavn={navn}>
            <div className="underside-innhold">
                <Normaltekst>{tekster.innhold}</Normaltekst>
                <LenkeHovedknapp hrefKey={tekster.sokStonadLenke}>{tekster.sokStonad}</LenkeHovedknapp>
            </div>
        </SidemalSenter>
    );
};

export default DagpengerAnnenstotte;
