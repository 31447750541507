import * as React from 'react';
import { defineMessages } from 'react-intl';
import { Panel } from 'nav-frontend-paneler';
import Okonomi from '../../bolker/okonomi/okonomi';
import { BannerProps } from '../../maler/bannermal';
import { intlbuilder } from '../../utils/intl-utils';
import SidemalSituasjon from '../../maler/sidemal-situasjon';
import DinSituasjon from '../../bolker/dinsituasjon/din-situasjon';
import JobbsokingMia from '../../bolker/jobbsoking-mia/jobbsoking-mia';
import RegistreringProvider from '../../bolker/registrering/registrering-provider';

const navn = 'utenfor-arbeidslivet-lenge';
const intl = intlbuilder(`${navn}.`);
const tekster = defineMessages({
    bannerTittel: intl.tekst('banner.tittel'),
});

const bannerProps = {
    tittel: tekster.bannerTittel
} as BannerProps;

const SituasjonUtenforArbeidslivetLenge: React.FunctionComponent = () => {
    const rader = [
        <DinSituasjon situasjon={navn} key="din-situasjon"/>,
        <JobbsokingMia situasjon={navn} key="jobbsoking"/>,
        <Okonomi situasjon={navn} key="okonomi"/>,
        <div className="registreringspanel-wrapper"><Panel border><RegistreringProvider situasjon={navn} key="registrering"/></Panel></div>
    ];
    return (
        <SidemalSituasjon bannerProps={bannerProps} rader={rader} sideNavn={navn}/>
    );
};

export default SituasjonUtenforArbeidslivetLenge;
