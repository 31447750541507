import * as React from 'react';
import { BannerProps } from './bannermal';
import Sidemal from './sidemal';
import './sidemal-situasjon.less';

interface SidemalSituasjonProps {
    bannerProps: BannerProps;
    rader: React.ReactNodeArray;
    className?: string;
    sideNavn: string;
    vinduTittelKey?: string;
}

const opprettRader = (rader: React.ReactNodeArray) => {
    return rader.map((rad, i) => {
        const klasse = (i % 2 === 0) ? 'rad' : 'rad rad-even';
        return (
            <div className={klasse} key={i}>
                <div className="limit">
                    {rad}
                </div>
            </div>
        );
    });
};

const SidemalSituasjon: React.FunctionComponent<SidemalSituasjonProps> = (props: SidemalSituasjonProps) => {

    return (
        <Sidemal bannerProps={props.bannerProps} className={props.className}
                 sideNavn={props.sideNavn} vinduTittelKey={props.vinduTittelKey}>
            <div className="situasjonsside__innhold">
                {opprettRader(props.rader)}
            </div>
        </Sidemal>
    );
};

export default SidemalSituasjon;
