import * as React from 'react';
import { Element, Normaltekst, Undertittel } from 'nav-frontend-typografi';
import Lenkeknapp from '../../components/lenkeknapp';
import { hentTekster } from '../../utils/intl-utils';
import bannerbilde from '../../img/oversikt-over-okonomien.svg';
import RouterProps from '../../utils/router-props';
import { BannerProps } from '../../maler/bannermal';
import SidemalSenter from '../../maler/sidemal-senter';

const intlTekster = (intl) => ({
    bannerTittel: intl.tekst('banner.tittel'),
    bannerIngress: intl.tekst('banner.ingress'),
    relatertTittel: intl.tekst('relatertinnhold.tittel'),
    relatertInnhold: intl.html('relatertinnhold.innhold', {values: andreYtelserLenke}),
    rettTilTiltakspenger: intl.tekst('undertittel.rett-til-tiltakspenger'),
    rettTilTiltakspengerIngress: intl.html('undertittel.rett-til-tiltakspenger.ingress'),
    innhold: intl.html('innhold'),
    sokTiltakspengerUrl: intl.key('lenke.sok-tiltakspenger.url'),
    sokTiltakspenger: intl.tekst('lenke.sok-tiltakspenger')
});

const andreYtelserLenke = {tiltakspengerandreytelser: 'andre-ytelser'};
const navn = 'tiltakspenger';

const Tiltakspenger: React.FunctionComponent<RouterProps> = (props: RouterProps) => {
    const tekster = hentTekster(intlTekster, `${props.match.params.situasjon}.${navn}.`);

    const bannerProps = {
        tittel: tekster.bannerTittel,
        ingress: tekster.bannerIngress,
        bildeSrc: bannerbilde,
        altTekst: ''
    } as BannerProps;

    return (
        <SidemalSenter bannerProps={bannerProps} className={navn} sideNavn={navn}>
            <div className="underside-innhold to-kol">
                <div className="hovedinnhold">
                    <Undertittel tag="h2" className="blokk-xxs">{tekster.rettTilTiltakspenger}</Undertittel>
                    <Normaltekst>{tekster.rettTilTiltakspengerIngress}</Normaltekst>
                    <div className="sjekkboks-liste blokk-l">
                        <Normaltekst>{tekster.innhold}</Normaltekst>
                    </div>
                    <Lenkeknapp hrefKey={tekster.sokTiltakspengerUrl} className="knapp--hoved">
                        <Normaltekst>{tekster.sokTiltakspenger}</Normaltekst>
                    </Lenkeknapp>
                </div>
                <div className="relatert-innhold-boks">
                    <div className="panel panel-fremhevet panel-stablet panel-ekstra-komprimert relatert-innhold">
                        <Element tag="h2">{tekster.relatertTittel}</Element>
                    </div>
                    <div className="relatert-innhold-lenkeliste">
                        <Normaltekst>{tekster.relatertInnhold}</Normaltekst>
                    </div>
                </div>
            </div>
        </SidemalSenter>
    );
};

export default Tiltakspenger;
