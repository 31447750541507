import * as React from 'react';
import classNames from 'classnames';
import { Sidetittel, Ingress } from 'nav-frontend-typografi';
import './bannermal.less';

export interface BannerProps {
    tittel: React.ReactNode;
    ingress?: string;
    className?: string;
    bildeSrc?: string;
    altTekst?: string;
}

function Banner(props: BannerProps) {

    const ingress = props.ingress ? (
            <Ingress>
                {props.ingress}
            </Ingress>
        )
        : undefined;

    const bilde = props.bildeSrc ? (
            <div className="banner-bilde-boks">
                <img src={props.bildeSrc} alt={props.altTekst} className="banner-bilde"/>
            </div>
        )
        : undefined;

    return (
        <div className={classNames('banner banner-innhold', props.className)}>
            <div className={!props.ingress && props.bildeSrc ? 'limit uten-ingress' : 'limit'}>
                <div className={!props.bildeSrc ? 'banner-tekst uten-bilde' : 'banner-tekst'}>
                    <Sidetittel className="blokk-xxs">
                        {props.tittel}
                    </Sidetittel>
                    {ingress}
                </div>
                {bilde}
            </div>
        </div>
    );
}

export default Banner;
