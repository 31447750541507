import * as React from 'react';
import { withRouter } from 'react-router';
import { Innholdstittel, Normaltekst } from 'nav-frontend-typografi';
import { Panel } from 'nav-frontend-paneler';
import { TilpassetLenkepanel } from '../../components/tilpasset-lenkepanel';
import Sidemal from '../../maler/sidemal';
import RouterProps from '../../utils/router-props';
import { hentTekster } from '../../utils/intl-utils';
import RegistreringProvider from '../../bolker/registrering/registrering-provider';
import './situasjonsvelger.less';

const intlTekster = (intl) => ({
    bannerTittel: intl.tekst('banner.tittel'),
    tittel: intl.tekst('tittel'),
    veiledning: intl.tekst('veiledning'),
    veiledningBeskrivelse: intl.tekst('veiledning-beskrivelse'),

    mistetJobben: intl.tekst('mistet-jobben-lenketekst'),
    mistetJobbenLenke: intl.key('mistet-jobben-lenke'),

    permittert: intl.tekst('permittert-lenketekst'),
    permittertLenke: intl.key('permittert-lenke'),

    utenforArbeids: intl.tekst('utenfor-arbeidslivet-lenketekst'),
    utenforArbeidsLenke: intl.key('utenfor-arbeidslivet-lenke'),

    bytteJobb: intl.tekst('bytte-jobb-lenketekst'),
    bytteJobbLenke: intl.key('bytte-jobb-lenke'),

    ungLiteErfaring: intl.tekst('ung-lite-erfaring-lenketekst'),
    ungLiteErfaringLenke: intl.key('ung-lite-erfaring-lenke')
});

const navn = 'situasjonsvelger';

const SituasjonsVelgerPage: React.FunctionComponent<RouterProps> = (props: RouterProps) => {
    const tekster = hentTekster(intlTekster, `${navn}.`);

    const bannerProps = {
        tittel: tekster.bannerTittel
    };

    return (
        <Sidemal bannerProps={bannerProps} className={navn} sideNavn={navn}>
            <div className="rad">
                <div className="limit">
                    <div className="underside-innhold">
                        <div className="panelkolonne blokk-s">
                            <Panel border>
                                <RegistreringProvider situasjon={navn} key="registrering"/>
                            </Panel>
                        </div>
                        <div className="panelkolonne blokk-s">
                            <Panel border>
                            <Innholdstittel className={'veiledning-tittel'}>
                                {tekster.veiledning}
                            </Innholdstittel>
                            <Normaltekst className="blokk-s">
                                { tekster.veiledningBeskrivelse }
                            </Normaltekst>
                            <TilpassetLenkepanel hrefKey={tekster.mistetJobbenLenke} data-testid="lenke-mistet-jobben">
                                {tekster.mistetJobben}
                            </TilpassetLenkepanel>
                            <TilpassetLenkepanel hrefKey={tekster.permittertLenke} data-testid="lenke-permittert">
                                {tekster.permittert}
                            </TilpassetLenkepanel>
                            <TilpassetLenkepanel hrefKey={tekster.utenforArbeidsLenke} data-testid="lenke-utenfor-arbeidslivet-lenge">
                                {tekster.utenforArbeids}
                            </TilpassetLenkepanel>
                            <TilpassetLenkepanel hrefKey={tekster.ungLiteErfaringLenke} data-testid="lenke-ung-lite-erfaring">
                                {tekster.ungLiteErfaring}
                            </TilpassetLenkepanel>
                            <TilpassetLenkepanel hrefKey={tekster.bytteJobbLenke} data-testid="lenke-bytte-jobb">
                                {tekster.bytteJobb}
                            </TilpassetLenkepanel>
                            </Panel>
                        </div>
                    </div>
                </div>
            </div>
        </Sidemal>
    );
};

export default withRouter(SituasjonsVelgerPage);
