import * as React from 'react';
import { Normaltekst, Innholdstittel } from 'nav-frontend-typografi';
import { TilpassetLenkepanel } from '../../components/tilpasset-lenkepanel';
import { TilpassetLenke } from '../../components/tilpasset-lenke';
import utklippbilde from '../../img/utklippstavlen.svg';
import ungbilde from '../../img/okonomisksstotte.svg';
import utenforbilde from '../../img/okonomisksstotte-utenfor-arbeidslivet.svg';
import infobilde from '../../img/info-dagpenger.svg';
import './okonomi-infoboks.less';

interface Props {
    situasjon: string;
    tekster: any;
}

const hentSvg = (situasjon: string) => {
    const svgConfig = {
        'ung-lite-erfaring': ungbilde,
        'utenfor-arbeidslivet-lenge': utenforbilde
    };
    return !svgConfig[situasjon] ? infobilde : svgConfig[situasjon];
};

const hentElement: React.FunctionComponent<Props> = (props: Props) => {
    const situasjonConfigs = {
        'bytte-jobb': (
            <TilpassetLenkepanel
                className="okonomiskstotte--lenkepanel"
                data-testid="lenke-okonomisk-stotte"
                hrefKey={props.tekster.stonadsBoksLenke}
            >
                <img src={utklippbilde} alt="Økonomisk støtte" className="okonomiskstotte--lenkepanel__illustrasjon"/>
                <div className="okonomiskstotte--lenkepanel__tekst">
                    <Innholdstittel tag="h2" className="lenkepanel__overskrift">{props.tekster.stonadHeader}</Innholdstittel>
                    <Normaltekst>{props.tekster.stonadInnhold}</Normaltekst>
                </div>
            </TilpassetLenkepanel>
        )
    };
    return situasjonConfigs[props.situasjon];
};

const hentDefaultElement: React.FunctionComponent<Props> = (props: Props) => {
    return (
        <div className={`okonomiskstotte okonomiskstotte--${props.situasjon}`}>
            <div className="okonomiskstotte__tekst">
                <Innholdstittel tag="h2" className="innholdstittel">
                    {props.tekster.stonadHeader}
                </Innholdstittel>
                <Normaltekst className="blokk-s">
                    {props.tekster.stonadInnhold}
                </Normaltekst>
                <TilpassetLenke hrefKey={props.tekster.stonadsBoksLenke} data-testid="lenke-okonomisk-stotte">
                    <Normaltekst tag="span">{props.tekster.stonadLenke}</Normaltekst>
                </TilpassetLenke>
            </div>
            <img src={hentSvg(props.situasjon)} alt="okonomiskstotte" className="okonomiskstotte__illustrasjon"/>
        </div>
    );
};

const TilpassetInfoboks: React.FunctionComponent<Props> = (props: Props) => {
    const element = hentElement(props);
    if (!element) {
        return hentDefaultElement(props);
    }
    return element;
};

export default TilpassetInfoboks;
