import * as React from 'react';
import { Innholdstittel, Normaltekst, Systemtittel, Undertittel } from 'nav-frontend-typografi';
import Veilederpanel from 'nav-frontend-veilederpanel';
import RouterProps from '../../utils/router-props';
import { hentTekster } from '../../utils/intl-utils';
import { BannerProps } from '../../maler/bannermal';
import andreHjelpeTjenesterBilde from '../../img/andre-hjelpetjenester-utenfor-nav.svg';
import hjelpetjenesterIkon from '../../img/hjelpetjenester.svg';
import barneombudetIkon from '../../img/barneombudet.svg';
import helseLivsstilSykdomIkon from '../../img/helse-livsstil-sykdom.svg';
import psykiskeHelseproblemerIkon from '../../img/psykisk_helsenorge.svg';
import psykiskeHelseproblemerUnder18Ikon from '../../img/psykiske-helseproblemer-under-18.svg';
import omRusOgAlkoholIkon from '../../img/om-rus-og-alkohol.svg';
import sporHelsesosterIkon from '../../img/spor-helsesoster.svg';
import akuttVanskeligIkon from '../../img/akutt.svg';
import slettMegIkon from '../../img/slett-meg.svg';
import './andre-hjelpetjenester.less';
import SidemalSituasjon from '../../maler/sidemal-situasjon';

const intlTekster = (intl) => ({
    bannerTittel: intl.tekst('tittel'),
    bannerIngress: intl.tekst('ingress'),
    tittel: intl.tekst('tittel'),
    ingress: intl.tekst('ingress'),
    helseLivsstilSykdomTittel: intl.tekst('helse-livsstil-og-sykdom.tittel'),
    helseLivsstilSykdomInnhold: intl.html('helse-livsstil-og-sykdom.innhold'),
    hjelpetjenesterAtilAaTittel: intl.tekst('hjelpetjenester-fra-a-aa.tittel'),
    hjelpetjenesterAtilAaInnhold: intl.html('hjelpetjenester-fra-a-aa.innhold'),
    omRusOgAlkoholTittel: intl.tekst('om-rus-og-alkohol.tittel'),
    omRusOgAlkoholInnhold: intl.html('om-rus-og-alkohol.innhold'),
    psykiskeHelseproblemerTittel: intl.tekst('har-du-psykiske-helseproblemer.tittel'),
    psykiskeHelseproblemerInnhold: intl.html('har-du-psykiske-helseproblemer.innhold'),
    psykiskeHelseproblemerUnder18aarTittel: intl.tekst('psykiske-helseproblemer-under-18-aar.tittel'),
    psykiskeHelseproblemerUnder18aarInnhold: intl.html('psykiske-helseproblemer-under-18-aar.innhold'),
    sporBarneombudetTittel: intl.tekst('spor-barneombudet.tittel'),
    sporBarneombudetInnhold: intl.html('spor-barneombudet.innhold'),
    sporHelsesosterTittel: intl.tekst('spor-helsesoster.tittel'),
    sporHelsesosterInnhold: intl.html('spor-helsesoster.innhold'),
    akuttVanskeligTittel: intl.tekst('akutt-vanskelig-situasjon.tittel'),
    akuttVanskeligUndertittel1: intl.tekst('akutt-vanskelig-situasjon.undertittel1'),
    akuttVanskeligUndertittel2: intl.tekst('akutt-vanskelig-situasjon.undertittel2'),
    akuttVanskeligInnhold1: intl.html('akutt-vanskelig-situasjon.innhold1'),
    akuttVanskeligInnhold2: intl.html('akutt-vanskelig-situasjon.innhold2'),
    sletteInnholdTittel: intl.tekst('slette-uonsket-innhold-tittel'),
    sletteInnholdInnhold: intl.html('slette-uonsket-innhold-innhold'),
});

const navn = 'andre-hjelpetjenester';

const AndreHjelpetjenester: React.FunctionComponent<RouterProps> = (props: RouterProps) => {
    const tekster = hentTekster(intlTekster, `${props.match.params.situasjon}.${navn}.`);

    const bannerProps = {
        tittel: tekster.bannerTittel,
        ingress: tekster.bannerIngress,
        bildeSrc: andreHjelpeTjenesterBilde
    } as BannerProps;

    const rader = [
        <div className="underside-innhold" key={0}>
            <div className="veilederpanelboks">
                <Veilederpanel type="plakat" svg={<img src={hjelpetjenesterIkon} alt=""/>}>
                    <Undertittel tag="h3" className="veilederpanel-tittel">{tekster.hjelpetjenesterAtilAaTittel}</Undertittel>
                    <Normaltekst className="veilederpanel-tekst">{tekster.hjelpetjenesterAtilAaInnhold}</Normaltekst>
                </Veilederpanel>

                <Veilederpanel type="plakat" svg={<img src={barneombudetIkon} alt=""/>}>
                    <Undertittel tag="h3" className="veilederpanel-tittel">{tekster.sporBarneombudetTittel}</Undertittel>
                    <Normaltekst className="veilederpanel-tekst">{tekster.sporBarneombudetInnhold}</Normaltekst>
                </Veilederpanel>

                <Veilederpanel type="plakat" svg={<img src={helseLivsstilSykdomIkon} alt=""/>}>
                    <Undertittel tag="h3" className="veilederpanel-tittel">{tekster.helseLivsstilSykdomTittel}</Undertittel>
                    <Normaltekst className="veilederpanel-tekst">{tekster.helseLivsstilSykdomInnhold}</Normaltekst>
                </Veilederpanel>

                <Veilederpanel type="plakat" svg={<img src={psykiskeHelseproblemerIkon} alt=""/>}>
                    <Undertittel tag="h3" className="veilederpanel-tittel">{tekster.psykiskeHelseproblemerTittel}</Undertittel>
                    <Normaltekst className="veilederpanel-tekst">{tekster.psykiskeHelseproblemerInnhold}</Normaltekst>
                </Veilederpanel>

                <Veilederpanel type="plakat" svg={<img src={psykiskeHelseproblemerUnder18Ikon} alt=""/>}>
                    <Undertittel tag="h3" className="veilederpanel-tittel">{tekster.psykiskeHelseproblemerUnder18aarTittel}</Undertittel>
                    <Normaltekst className="veilederpanel-tekst">{tekster.psykiskeHelseproblemerUnder18aarInnhold}</Normaltekst>
                </Veilederpanel>

                <Veilederpanel type="plakat" svg={<img src={omRusOgAlkoholIkon} alt=""/>}>
                    <Undertittel tag="h3" className="veilederpanel-tittel">{tekster.omRusOgAlkoholTittel}</Undertittel>
                    <Normaltekst className="veilederpanel-tekst">{tekster.omRusOgAlkoholInnhold}</Normaltekst>
                </Veilederpanel>

                <Veilederpanel type="plakat" svg={<img src={sporHelsesosterIkon} alt=""/>}>
                    <Undertittel tag="h3" className="veilederpanel-tittel">{tekster.sporHelsesosterTittel}</Undertittel>
                    <Normaltekst className="veilederpanel-tekst">{tekster.sporHelsesosterInnhold}</Normaltekst>
                </Veilederpanel>
            </div>
        </div>,

        <div className="underside-innhold akutt-vanskelig" key={1}>
            <Innholdstittel tag="h2" className="akutt-vanskelig-tittel">{tekster.akuttVanskeligTittel}</Innholdstittel>
            <Veilederpanel type="plakat" kompakt={true} svg={<img src={akuttVanskeligIkon} alt=""/>}>
                <div className="dobbel-kol">
                    <div className="kol">
                        <Undertittel tag="h3">{tekster.akuttVanskeligUndertittel1}</Undertittel>
                        <Normaltekst>{tekster.akuttVanskeligInnhold1}</Normaltekst>
                    </div>
                    <div className="kol">
                        <Undertittel tag="h3">{tekster.akuttVanskeligUndertittel2}</Undertittel>
                        <Normaltekst>{tekster.akuttVanskeligInnhold2}</Normaltekst>
                    </div>
                </div>
            </Veilederpanel>
        </div>,

        <div className="underside-innhold" key={2}>
            <div className="slette-innhold">
                <div className="slette-innhold--tekst">
                    <Systemtittel tag="h2" className="slette-innhold-tittel">{tekster.sletteInnholdTittel}</Systemtittel>
                    <Normaltekst>{tekster.sletteInnholdInnhold}</Normaltekst>
                </div>
                <div className="slette-innhold--bilde">
                    <img src={slettMegIkon} alt=""/>
                </div>
            </div>
        </div>
    ];

    return (
        <SidemalSituasjon bannerProps={bannerProps} className={navn} sideNavn={navn} rader={rader}/>
    );
};

export default AndreHjelpetjenester;
