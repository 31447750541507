import * as React from 'react';
import SituasjonProps from '../../utils/situasjon-props';
import { hentTekster } from '../../utils/intl-utils';
import { Innholdstittel, Normaltekst } from 'nav-frontend-typografi';
import { TilpassetLenke } from '../../components/tilpasset-lenke';

import './jobb-eu-eos.less';
import JobbEuEosBilde from '../../img/jobb-eu-eos.svg';

const intlTekster = (intl) => ({
    tittel: intl.tekst('tittel'),
    innhold: intl.tekst('innhold'),
    lenke: intl.tekst('lenke'),
    lenkeURL: intl.key('lenke.url')
});

const JobbEuEosBolk: React.FunctionComponent<SituasjonProps> = (props: SituasjonProps) => {

    const tekster = hentTekster(intlTekster, `${props.situasjon}.jobb-eu-eos-bolk.`);

    return (
        <div className="jobb-eu-eos-bolk">
            <div className="jobb-eu-eos-bolk__tekst-innhold">
                <Innholdstittel tag="h2" className="innholdstittel">
                    {tekster.tittel}
                </Innholdstittel>
                <Normaltekst className="blokk-s">
                    {tekster.innhold}
                </Normaltekst>
                <TilpassetLenke hrefKey={tekster.lenkeURL} className="lenke" data-testid="lenke-jobb-eu-eos">
                    <Normaltekst tag="span">{tekster.lenke}</Normaltekst>
                </TilpassetLenke>
            </div>
            <div className="jobb-eu-eos-bolk__bilde">
                <img src={JobbEuEosBilde} alt="Jobbe i EU/EØS og Sveits"/>
            </div>
        </div>
    );
};

export default JobbEuEosBolk;
