import * as React from 'react';
import { Normaltekst } from 'nav-frontend-typografi';
import { hentTekster } from '../../../utils/intl-utils';

const intlTekster = (intl) => ({
    innhold: intl.html('innhold')
});

const navn = 'aap-sok';

const SokInnhold = ({situasjon}) => {
    const tekster = hentTekster(intlTekster, `${situasjon}.${navn}.`);

    return (
        <div className="panel-innhold sok-innhold sok-om-liste">
            <Normaltekst className={navn}>{tekster.innhold}</Normaltekst>
        </div>
    );
};

export default SokInnhold;
