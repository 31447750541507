import * as React from 'react';
import Registrering from './registrering';
import Oppfolging from '../../providers/oppfolging';
import Innlogging from '../../providers/innlogging';
import Innloggingsstatus from '../../providers/innloggingsstatus';
import LedigeStillinger from '../../providers/ledige-stillinger'

interface Props {
    situasjon: string;
}

const RegistreringProvider: React.FunctionComponent<Props> = (props: Props) => {
    return (
        <Innloggingsstatus>
            <Innlogging>
                <Oppfolging>
                    <LedigeStillinger>
                        <Registrering situasjon={props.situasjon}/>
                    </LedigeStillinger>
                </Oppfolging>
            </Innlogging>
        </Innloggingsstatus>
    );
};

export default RegistreringProvider;
