import * as React from 'react';
import { settParamsContext } from '../hocs/params-context';
import { injectIntl } from 'react-intl';
import { hrefResolver } from '../utils/intl-utils';
import { Link } from 'react-router-relative-link';

const harTomtInnhold = (hrefKey, resolvedHref) => (
    hrefKey === 'void0' ||
    hrefKey === 'void 0' ||
    resolvedHref === 'void0' ||
    resolvedHref === 'void 0'
);

interface Props {
    hrefKey?: string;
    href?: string;
    children?: React.ReactNode;
    className?: string;
    intl?: any;
    'data-testid'?: string;
    params?: any;
}

const lenkepanelBase: React.FunctionComponent<Props> = (props: Props) => {
    const resolved = hrefResolver(props.href, props.hrefKey, props.intl, props.params);

    if (resolved.href === undefined) {
        return null;
    }

    let klasse = '';
    if (props.className === undefined) {
        klasse = '';
    } else {
        klasse = 'lenke ' + props.className;
    }

    if (resolved.href.startsWith('http') || resolved.href.startsWith('/')) {
        return (
            <a href={resolved.href} className={klasse} data-testid={props['data-testid']}>
                {props.children}
            </a>
        );
    }

    if (harTomtInnhold(props.hrefKey, resolved.href)) {
        return null;
    }

    return (
        <Link to={resolved.href} className={'lenke ' + props.className} data-testid={props['data-testid']}>
            {props.children}
        </Link>
    );
};

const LenkepanelBase = injectIntl(settParamsContext(lenkepanelBase));
export default LenkepanelBase;

export const TilpassetLenke = (props) =>
    <LenkepanelBase {...props} />;
