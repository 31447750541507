import * as React from 'react';
import { Element, Ingress, Normaltekst } from 'nav-frontend-typografi';
import { hentTekster } from '../../utils/intl-utils';
import bannerbilde from '../../img/oversikt-over-okonomien.svg';
import SidemalSenter from '../../maler/sidemal-senter';
import RouterProps from '../../utils/router-props';
import { BannerProps } from '../../maler/bannermal';

const intlTekster = (intl) => ({
    bannerTittel: intl.html('banner.tittel'),
    bannerIngress: intl.tekst('banner.ingress'),
    vinduTittelKey: intl.key('vindu.tittel'),
    relatertTittel: intl.tekst('relatertinnhold.tittel'),
    relatertInnhold: intl.html('relatertinnhold.innhold'),
    ingress: intl.html('ingress'),
    egress: intl.html('egress'),
    innhold: intl.html('innhold')
});

const navn = 'kvalifiseringsprogrammet';

const Kvalifiseringsprogrammet: React.FunctionComponent<RouterProps> = (props: RouterProps) => {
    const tekster = hentTekster(intlTekster, `${props.match.params.situasjon}.${navn}.`);

    const bannerProps = {
        tittel: tekster.bannerTittel,
        ingress: tekster.bannerIngress,
        bildeSrc: bannerbilde,
        altTekst: ''
    } as BannerProps;

    return (
        <SidemalSenter bannerProps={bannerProps} className={navn} sideNavn={navn} vinduTittelKey={tekster.vinduTittelKey}>
            <div className="underside-innhold to-kol">
                <div className="hovedinnhold">
                    <Ingress>{tekster.ingress}</Ingress>
                    <div className="sjekkboks-liste blokk-l">
                        <Normaltekst>{tekster.innhold}</Normaltekst>
                    </div>
                    <Normaltekst>{tekster.egress}</Normaltekst>
                </div>
                <div className="relatert-innhold-boks">
                    <div className="panel relatert-innhold">
                        <Element tag="h2">{tekster.relatertTittel}</Element>
                    </div>
                    <div className="relatert-innhold-lenkeliste">
                        <Normaltekst>{tekster.relatertInnhold}</Normaltekst>
                    </div>
                </div>
            </div>
        </SidemalSenter>
    );
};

export default Kvalifiseringsprogrammet;
