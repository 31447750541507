import * as React from 'react';
import { hentTekster } from '../../utils/intl-utils';
import Ekspanderbartpanel from 'nav-frontend-ekspanderbartpanel';
import bannerBilde from '../../img/dagpenger.svg';
import './dagpenger.less';

import SierOppInnhold from './sier-opp/sier-opp-innhold';
import BelopInnhold from './belop/belop-innhold';
import SoknadsprosessInnhold from './soknadsprosess/sokandsprosess-innhold';
import RettPaDagpengerInnhold from './rett-pa-dagpenger/rett-pa-dagpenger-innhold';
import SoketidspunktInnhold from './soketidspunkt/soketidspunkt-innhold';
import RouterProps from '../../utils/router-props';
import { BannerProps } from '../../maler/bannermal';
import SidemalSenter from '../../maler/sidemal-senter';
import { getUrlHashValue } from '../../utils/url-utils';

const intlTeksterDefaultPage = (intl) => ({
    bannerTittel: intl.tekst('banner.tittel'),
    bannerIngress: intl.tekst('banner.ingress'),
    rettPaTittel: intl.tekst('tittel.rett-pa'),
    belopTittel: intl.tekst('tittel.belop'),
    soknadsprosessTittel: intl.tekst('tittel.soknadsprosess'),
    soketidspunktTittel: intl.tekst('tittel.soketidspunkt')
});

const intlTeksterBytteJobbPage = (intl) => ({
    bannerTittel: intl.tekst('banner.tittel'),
    bannerIngress: intl.tekst('banner.ingress'),
    sierOppTittel: intl.tekst('tittel.sier-opp'),
    belopTittel: intl.tekst('tittel.belop'),
    soknadsprosessTittel: intl.tekst('tittel.soknadsprosess')
});

const Dagpenger: React.FunctionComponent<RouterProps> = (props: RouterProps) => {
    const situasjon = props.match.params.situasjon;
    if (situasjon === 'bytte-jobb') {
        return DagpengerBytteJobbPage({situasjon});
    } else {
        return DagpengerDefaultPage({situasjon});
    }
};

interface DagpengerPageProps {
    situasjon: string;
}

const navn = 'dagpenger';

const DagpengerDefaultPage: React.FunctionComponent<DagpengerPageProps> = (props: DagpengerPageProps) => {
    const situasjon = props.situasjon;
    const tekster = hentTekster(intlTeksterDefaultPage, `${situasjon}.${navn}.`);
    const hash = getUrlHashValue();

    const bannerProps = {
        tittel: tekster.bannerTittel,
        ingress: tekster.bannerIngress,
        bildeSrc: bannerBilde
    } as BannerProps;

    return (
        <SidemalSenter bannerProps={bannerProps} className={navn} sideNavn={navn}>
            <div className="underside-innhold">
                <Ekspanderbartpanel tittel={tekster.rettPaTittel} tittelProps="undertittel" border={true} apen={hash === '1'}>
                    <RettPaDagpengerInnhold situasjon={situasjon}/>
                </Ekspanderbartpanel>
                <Ekspanderbartpanel tittel={tekster.soketidspunktTittel} tittelProps="undertittel"
                                    data-testid="panel-soknadstidspunkt" border={true} apen={hash === '2'}>
                    <SoketidspunktInnhold situasjon={situasjon}/>
                </Ekspanderbartpanel>
                <Ekspanderbartpanel tittel={tekster.belopTittel} tittelProps="undertittel" border={true} apen={hash === '3'}>
                    <BelopInnhold situasjon={situasjon}/>
                </Ekspanderbartpanel>
                <Ekspanderbartpanel tittel={tekster.soknadsprosessTittel} tittelProps="undertittel" border={true} apen={hash === '4'}>
                    <SoknadsprosessInnhold situasjon={situasjon}/>
                </Ekspanderbartpanel>
            </div>
        </SidemalSenter>
    );
};

const DagpengerBytteJobbPage: React.FunctionComponent<DagpengerPageProps> = (props: DagpengerPageProps) => {
    const situasjon = props.situasjon;
    const tekster = hentTekster(intlTeksterBytteJobbPage, `${situasjon}.${navn}.`);
    const hash = getUrlHashValue();

    const bannerProps = {
        tittel: tekster.bannerTittel,
        ingress: tekster.bannerIngress,
        bildeSrc: bannerBilde
    } as BannerProps;

    return (
        <SidemalSenter bannerProps={bannerProps} className={navn} sideNavn={navn}>
            <div className="underside-innhold">
                <Ekspanderbartpanel tittel={tekster.sierOppTittel} tittelProps="undertittel" border={true} apen={hash === '1'}>
                    <SierOppInnhold situasjon={situasjon}/>
                </Ekspanderbartpanel>
                <Ekspanderbartpanel tittel={tekster.belopTittel} tittelProps="undertittel" border={true} apen={hash === '2'}>
                    <BelopInnhold situasjon={situasjon}/>
                </Ekspanderbartpanel>
                <Ekspanderbartpanel tittel={tekster.soknadsprosessTittel} tittelProps="undertittel" border={true} apen={hash === '3'}>
                    <SoknadsprosessInnhold situasjon={situasjon}/>
                </Ekspanderbartpanel>
            </div>
        </SidemalSenter>
    );
};

export default Dagpenger;
