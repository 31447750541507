import * as React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import Datepicker from '../../../components/datepicker/react-datepicker';
import { hentTekster } from '../../../utils/intl-utils';
import AlertStripe from 'nav-frontend-alertstriper';
import SituasjonProps from '../../../utils/situasjon-props';
import { loggAntallDagerMellomDagensDatoOgSisteDagMedLonn } from '../../../metrics';

interface State {
    textvalue: string;
    visning: any;
    harfeil: boolean;
    timeStamp: number;
}

const intlTekster = (intl) => ({
    label: intl.tekst('label'),
    knapp: intl.tekst('knapp'),
    resultatIdag: intl.html('resultat.idag'),
    resultatPeriode: (periode) => intl.tekst('resultat.periode', {values: {periode}}),
    feilmeldingIngenInput: intl.tekst('feilmelding.ingen-input'),
    feilmeldingUgyldigInput: intl.tekst('feilmelding.ugyldig-input')
});

const byggDatostreng = (dato, tekster) => {
    const maxDato = moment(dato).subtract(1, 'days');
    const minDato = moment(dato).subtract(7, 'days');
    const year = minDato.year();

    if (minDato.isBefore(moment(), 'day')) {
        return tekster.resultatIdag;
    }
    if (minDato.year() !== maxDato.year()) {
        return tekster.resultatPeriode(`${minDato.format('LL')} - ${maxDato.format('LL')}.`);
    } else if (minDato.month() !== maxDato.month()) {
        const minDatoStreng = minDato.format('LL').replace(year.toString(), '').trim();
        const maxDatoStreng = maxDato.format('LL').replace(year.toString(), '').trim();
        return tekster.resultatPeriode(`${minDatoStreng} - ${maxDatoStreng} ${year}.`);
    }
    const month = minDato.format('MMMM');

    return tekster.resultatPeriode(`${minDato.date()}. - ${maxDato.date()}. ${month} ${year}.`);
};

const momentDato = (dato) => moment(dato, 'DD.MM.YYYY');

class DagpengerDato extends React.Component<SituasjonProps, State> {
    tekster: any;

    constructor(props: SituasjonProps) {
        super(props);
        this.tekster = hentTekster(intlTekster, `${this.props.situasjon}.dagpenger.soketidspunkt.dato.`);
        this.state = {
            textvalue: '',
            visning: null,
            harfeil: false,
            timeStamp: 0
        };
    }

    endreDato = (event: any) => {
        const target = event.target;
        if (target.tagName === 'INPUT' && target.value !== this.state.textvalue) {
            let value;
            if (target.type === 'date') {
                value = moment(target.value).format('DD.MM.YYYY');
            } else {
                value = target.value;
            }
            this.setState({textvalue: value, visning: null, timeStamp: new Date().getTime()});
        }
    }

    visEndring = (e: any) => {
        e.preventDefault();

        // focus blir flyttet til input-feltet av datepicker-biblioteket, enter for å velge dato blir derfor også en
        // submit av formet. Vi sjekker at det har gått litt tid mellom dato-endringen og submit for å unngå submit fra
        // dato-dropdown-en.
        // Vi bruker new Date() fremfor event.timeStamp pga firefox og inkonsistent timeStamp for ulike events
        const currentTimestamp = new Date().getTime();
        if (currentTimestamp - this.state.timeStamp < 50) {
            return;
        }

        const dato = momentDato(this.state.textvalue);

        const dagensDato = moment().startOf('day');
        const differanse = dato.diff(dagensDato, 'days');
        loggAntallDagerMellomDagensDatoOgSisteDagMedLonn(differanse);

        if (dato.isValid()) {
            const datostreng = byggDatostreng(dato, this.tekster);
            const datopanel = (
                <AlertStripe type="info" data-testid="element-dato-anbefaling" className="alert-stripe-tekst">
                    <p className="blokk-xxxs">{datostreng}</p>
                </AlertStripe>
            );

            this.setState({visning: datopanel, harfeil: false});
        } else if (!this.state.textvalue || this.state.textvalue.length === 0) {
            const feilmelding = <p data-testid="element-dato-error" className="skjema-feilmelding">{this.tekster.feilmeldingIngenInput}</p>;
            this.setState({visning: feilmelding, harfeil: true});
        } else {
            const feilmelding = <p data-testid="element-dato-error" className="skjema-feilmelding">{this.tekster.feilmeldingUgyldigInput}</p>;
            this.setState({visning: feilmelding, harfeil: true});
        }
    }

    render() {
        const formClassname = classNames(
            'blokk-m',
            'sluttdato-form',
            {'har-valideringsfeil': this.state.harfeil}
        );

        return (
            <form className={formClassname} onSubmit={this.visEndring}>
                <Datepicker
                    className="datovelger-stor-inline blokk-m"
                    value={this.state.textvalue}
                    onChange={this.endreDato}
                    onKeyUp={this.endreDato}
                    data-testid="element-datofelt"
                >
                    <span className="hjelpetekst-parent">
                        {this.tekster.label}
                    </span>
                </Datepicker>
                <button type="submit" className="knapp knapp--hoved blokk-xxs" data-testid="knapp-datoutregning">
                    {this.tekster.knapp}
                </button>
                {this.state.visning}
            </form>
        );
    }
}

export default DagpengerDato;
