import * as React from 'react';
import { hentTekster } from '../../../utils/intl-utils';
import { TilpassetLenkepanel } from '../../../components/tilpasset-lenkepanel';
import jobblystFacebookBilde from '../../../img/jobblystfacebook.svg';
import { Normaltekst, Systemtittel } from 'nav-frontend-typografi';
import './jobblyst-facebook.less';
import SituasjonProps from '../../../utils/situasjon-props';

const intlTekster = (intl) => ({
    tittel: intl.tekst('tittel'),
    ingress: intl.tekst('ingress'),
    url: intl.key('url')
});

const JobblystFacebookBolk: React.FunctionComponent<SituasjonProps> = (props: SituasjonProps) => {
    const tekster = hentTekster(intlTekster, `${props.situasjon}.jobblyst-facebook-bolk.`);

    return (
        <div className="jobblyst-facebook">
            <TilpassetLenkepanel className="lenkepanel-med-bilde" hrefKey={tekster.url} target="_blank">
                <img src={jobblystFacebookBilde} alt="Jobblyst på facebook" className="lenkepanel-med-bilde__illustrasjon"/>
                <div className="lenkepanel-med-bilde__tekst">
                    <Systemtittel tag="h3" className="lenkepanel-med-bilde__overskrift">{tekster.tittel}</Systemtittel>
                    <Normaltekst tag="span" className="lenke ingress-avstand">{tekster.ingress}</Normaltekst>
                </div>
            </TilpassetLenkepanel>
        </div>
    );
};

export default JobblystFacebookBolk;
