import * as React from 'react';
import { Innholdstittel, Normaltekst } from 'nav-frontend-typografi';
import { hentTekster } from '../../utils/intl-utils';
import Ekspanderbartpanel from 'nav-frontend-ekspanderbartpanel';
import RouterProps from '../../utils/router-props';
import SidemalSenter from '../../maler/sidemal-senter';
import { BannerProps } from '../../maler/bannermal';
import bannerbilde from '../../img/oversikt-over-okonomien.svg';
import './okonomi-oversikt.less';

const innholdsbolk = (id: number, tekster) => (
    <Ekspanderbartpanel
        tittel={tekster.grepheader(id)}
        tittelProps="undertittel"
        border={true}
    >
        <div className="panel-innhold">
            <Normaltekst>{tekster.grepinnhold(id)}</Normaltekst>
        </div>
    </Ekspanderbartpanel>
);

const intlTekster = (intl) => ({
    bannerTittel: intl.tekst('tittel'),
    bannerIngress: intl.tekst('ingress'),
    header: intl.tekst('innhold.header'),
    grepheader: (tips) => intl.tekst(`grep.${tips}.header`),
    grepinnhold: (tips) => intl.html(`grep.${tips}.innhold`, true)
});

const navn = 'okonomi-oversikt';

const OkonomiOversikt: React.FunctionComponent<RouterProps> = (props: RouterProps) => {
    const tekster = hentTekster(intlTekster, `${props.match.params.situasjon}.${navn}.`);

    const bannerProps = {
        tittel: tekster.bannerTittel,
        ingress: tekster.bannerIngress,
        bildeSrc: bannerbilde,
    } as BannerProps;

    return (
        <SidemalSenter bannerProps={bannerProps} className={navn} sideNavn={navn}>
            <div className="underside-innhold">
                <Innholdstittel tag="h2" className="blokk-m">{tekster.header}</Innholdstittel>
                {innholdsbolk(1, tekster)}
                {innholdsbolk(2, tekster)}
                {innholdsbolk(3, tekster)}
                {innholdsbolk(4, tekster)}
                {innholdsbolk(5, tekster)}
            </div>
        </SidemalSenter>
    );
};

export default OkonomiOversikt;
