import * as React from 'react';
import { Normaltekst, Systemtittel } from 'nav-frontend-typografi';
import { TilpassetLenke } from '../../components/tilpasset-lenke';

import jobbsokerkompetansebilde from '../../img/jobbsokerkompetanse.svg';
import './jobbsoking-infoboks.less';

interface Props {
    situasjon: string;
    tekster: any;
}

const JobbsokingInfoboks: React.FunctionComponent<Props> = (props: Props) => {
    return (
        <div className="infoboks jobbsoking-infoboks">
            <div className="infoboks__tekst">
                <Systemtittel tag="h2" className="blokk-xs">
                    {props.tekster.tittel}
                </Systemtittel>
                <Normaltekst className="blokk-m">
                    {props.tekster.ingress}
                </Normaltekst>
            </div>
            <div className="infoboks__lenke">
                <TilpassetLenke
                    data-testid="lenke-aktiviteter"
                    hrefKey={props.tekster.lenkeUrl}
                    params={{situasjon: props.situasjon}}
                    className="lenke"
                >
                    <Normaltekst tag="span">{props.tekster.lenkeTekst}</Normaltekst>
                </TilpassetLenke>
            </div>
            <div className="infoboks__bilde">
                <img src={jobbsokerkompetansebilde} alt="Slik får du jobben"
                    className="infoboks__illustrasjon"
                />
            </div>
        </div>
    );
};

export default JobbsokingInfoboks;
