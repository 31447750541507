import * as React from 'react';
import { hentTekster } from '../utils/intl-utils';
import RouterProps from '../utils/router-props';
import { BannerProps } from '../maler/bannermal';
import SidemalSenter from '../maler/sidemal-senter';
import { Element, Ingress, Normaltekst } from 'nav-frontend-typografi';

const intlTekster = (intl) => ({
    bannerTittel: intl.tekst('banner.tittel'),
    bannerIngress: intl.tekst('banner.ingress'),
    relatertTittel: intl.tekst('relatertinnhold.tittel'),
    relatertInnhold: intl.html('relatertinnhold.innhold'),
    rettTilOksosIngress: intl.html('undertittel.rett-til-oksos.ingress'),
    innhold: intl.html('innhold'),
    egress: intl.html('egress')
});

const navn = 'okonomisksosialhjelp';

const Okonomisksosialhjelp: React.FunctionComponent<RouterProps> = (props: RouterProps) => {
    const tekster = hentTekster(intlTekster, `${props.match.params.situasjon}.${navn}.`);

    const bannerProps = {
        tittel: tekster.bannerTittel,
        ingress: tekster.bannerIngress
    } as BannerProps;

    return (
        <SidemalSenter bannerProps={bannerProps} className={navn} sideNavn={navn}>
            <div className="underside-innhold to-kol">
                <div className="hovedinnhold">
                    <Ingress>{tekster.rettTilOksosIngress}</Ingress>
                    <div className="sjekkboks-liste blokk-m">
                        <Normaltekst>{tekster.innhold}</Normaltekst>
                    </div>
                    <Normaltekst>{tekster.egress}</Normaltekst>
                </div>
                <div className="relatert-innhold-boks">
                    <div className="panel relatert-innhold">
                        <Element tag="h2">{tekster.relatertTittel}</Element>
                    </div>
                    <div className="relatert-innhold-lenkeliste">
                        <Normaltekst>{tekster.relatertInnhold}</Normaltekst>
                    </div>
                </div>
            </div>
        </SidemalSenter>
    );
};

export default Okonomisksosialhjelp;
