import * as React from 'react';
import { BannerProps } from './bannermal';
import Sidemal from './sidemal';

import './sidemal-situasjon.less';
import './sidemal-senter.less';

interface SidemalSenterProps {
    bannerProps: BannerProps;
    children: React.ReactNode;
    className?: string;
    sideNavn: string;
    vinduTittelKey?: string;
}

const SidemalSenter: React.FunctionComponent<SidemalSenterProps> = (props: SidemalSenterProps) => {
    return (
        <Sidemal bannerProps={props.bannerProps} className={props.className}
                 sideNavn={props.sideNavn} vinduTittelKey={props.vinduTittelKey}>
            <div className="rad">
                <div className="limit">
                    {props.children}
                </div>
            </div>
        </Sidemal>
    );
};

export default SidemalSenter;
