import { combineReducers } from 'redux';
import innloggingsstatusReducer, { InnloggingsstatusState } from './innloggingsstatus';
import oppfolgingReducer, { OppfolgingState } from './oppfolging';
import featureToggleReducer, { FeatureToggleState } from './feature-toggle';
import ledigeStillingerReducer, { LedigeStillingerState } from './ledige-stillinger';

export interface AppState {
    innloggingsstatus: InnloggingsstatusState;
    oppfolging: OppfolgingState;
    featureToggle: FeatureToggleState;
    ledigeStillinger: LedigeStillingerState;
}

export default combineReducers<AppState>({
    innloggingsstatus: innloggingsstatusReducer,
    oppfolging: oppfolgingReducer,
    featureToggle: featureToggleReducer,
    ledigeStillinger: ledigeStillingerReducer
});
