import * as React from 'react';
import DagpengerDato from './dagpenger-dato';
import { hentTekster } from '../../../utils/intl-utils';
import { Normaltekst } from 'nav-frontend-typografi';
import SituasjonProps from '../../../utils/situasjon-props';
import './soketidspunkt-innhold.less';

const intlTekster = (intl) => ({
    intro: intl.tekst('intro'),
    definisjonslisteInnhold: intl.html('definisjonsliste-innhold')
});

const SoketidspunktInnhold: React.FunctionComponent<SituasjonProps> = (props: SituasjonProps) => {
    const tekster = hentTekster(intlTekster, `${props.situasjon}.dagpenger.soketidspunkt.`);

    return (
        <div className="panel-innhold soketidspunkt-innhold">
            <DagpengerDato situasjon={props.situasjon}/>
            <Normaltekst>{tekster.definisjonslisteInnhold}</Normaltekst>
        </div>
    );
};

export default SoketidspunktInnhold;
