import * as React from 'react';
import { Normaltekst } from 'nav-frontend-typografi';
import RouterProps from '../../utils/router-props';
import SidemalSenter from '../../maler/sidemal-senter';
import { hentTekster } from '../../utils/intl-utils';
import { BannerProps } from '../../maler/bannermal';
import moteNavBilde from '../../img/mote-nav.svg';
import './mote-med-nav.less';

const intlTekster = (intl) => ({
    bannerTittel: intl.tekst('tittel'),
    bannerIngress: intl.tekst('ingress'),
    innhold: intl.html('innhold')
});

const navn = 'mote-med-nav';

const MoteMedNav: React.FunctionComponent<RouterProps> = (props: RouterProps) => {
    const tekster = hentTekster(intlTekster, `${props.match.params.situasjon}.${navn}.`);

    const bannerProps = {
        tittel: tekster.bannerTittel,
        ingress: tekster.bannerIngress,
        bildeSrc: moteNavBilde
    } as BannerProps;

    return (
        <SidemalSenter bannerProps={bannerProps} className={navn} sideNavn={navn}>
            <div className="underside-innhold">
                <Normaltekst>{tekster.innhold}</Normaltekst>
            </div>
        </SidemalSenter>
    );
};

export default MoteMedNav;
