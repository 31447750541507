import * as React from 'react';
import { Normaltekst } from 'nav-frontend-typografi';
import { hentTekster } from '../../utils/intl-utils';
import { BannerProps } from '../../maler/bannermal';
import RouterProps from '../../utils/router-props';
import SidemalSenter from '../../maler/sidemal-senter';

import bannerBilde from '../../img/arbeidstrening.svg';
import './arbeidstrening.less';

const intlTekster = (intl) => ({
    bannerTittel: intl.tekst('tittel'),
    bannerIngress: intl.tekst('ingress'),
    innhold: intl.html('innhold', true),
    sokStonad: intl.tekst('sok-stonad'),
    sokStonadLenke: intl.key('sok-stonad-lenke')
});

const navn = 'arbeidstrening';

const Arbeidstrening: React.FunctionComponent<RouterProps> = (props: RouterProps) => {
    const tekster = hentTekster(intlTekster, `${props.match.params.situasjon}.${navn}.`);

    const bannerProps = {
        tittel: tekster.bannerTittel,
        ingress: tekster.bannerIngress,
        bildeSrc: bannerBilde,
    } as BannerProps;

    return (
        <SidemalSenter bannerProps={bannerProps} className={navn} sideNavn={navn}>
            <div className="underside-innhold">
                <Normaltekst>{tekster.innhold}</Normaltekst>
            </div>
        </SidemalSenter>
    );
};

export default Arbeidstrening;
