class DatePicker {
    constructor(element, options) {
        DatePicker.instances = DatePicker.instances || [];
        DatePicker.INIT_COUNTER++;

        this.props = {
            monthNames: ['Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni', 'Juli',
                'August', 'September', 'Oktober', 'November', 'Desember'],
            dayNames: ['', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag', 'Søndag'],
            dateFormat: 'dd.mm.åååå',
            inputLabel: 'Datoformat er dd.mm.åååå, f.eks 24.12.2020. Men alle feltskilletegn blir godtatt',
            inputFormat: /^\d\d\.\d\d\.\d\d\d\d$/,
            bn_dateTitle: 'Velg dato...',
            errMsg: 'Javascript må være aktivert',
            bn_prevLabel: 'Gå til forrige måned',
            bn_nextLabel: 'Gå til neste måned',
            bn_infoLabel: 'Vis tastatursnarveier',
            keyboard: {
                heading: 'Tastatursnarveier - Gå til...',
                arrowLeftRight: { win: 'Pil venstre/høyre', mac: 'Pil venstre/høyre', text: 'forrige/neste dag' },
                arrowRight: { win: 'Pil høyre', mac: 'Pil høyre', text: 'neste dag' },
                arrowUpDown: { win: 'Pil opp/ned', mac: 'Pil opp/ned', text: 'forrige/neste uke' },
                pageUpDown: { win: 'pgUp/pgDown', mac: 'Fn + Pil opp/ned', text: 'forrige/neste måned' },
                ctrlPageUpDown: {
                    win: 'Shift + pgUp/pgDown',
                    mac: 'Ctrl + Fn + Pil opp/ned',
                    text: 'forrige/neste år'
                },
                homeEnd: { win: 'Home/End', mac: 'Ctrl + Fn + Pil venstre/høyre', text: 'første/siste dag i måneden' }
            },
            hideOnDocumentClick: false
        };
        this.globalListeners = {};

        if (options.props !== undefined) {
            this.props = { ...this.props, ...options.props };
        }

        this.id = 'dp-' + DatePicker.INIT_COUNTER.toString();
        this.input = element; // element to attach widget to
        this.input.setAttribute('id', this.id);
        this.input.setAttribute('placeholder', this.props.dateFormat);
        element.parentNode.style.whiteSpace = 'nowrap';
        element.parentNode.style.position = 'relative';
        this.label = element.parentNode.querySelector('label');
        this.label.setAttribute('for', this.id);

        this.isMac = navigator.platform.indexOf('Mac') > -1;
        this.isDesktop = !('ontouchstart' in document.documentElement);
        this.hasNativeDateSupport = this.input.type === 'date';
        this.useFallback = this.isDesktop || !this.hasNativeDateSupport;

        if (this.useFallback) {
            this.input.setAttribute('type', 'text');
        } else {
            return;
        }

        let minarr = [];
        this.minDate = null;
        if (options.minDate !== undefined) {
            minarr = options.minDate.split('.');
            this.minDate = new Date(minarr[2], minarr[1] - 1, minarr[0]);
        }
        let maxarr = [];
        this.maxDate = null;
        if (options.maxDate !== undefined) {
            maxarr = options.maxDate.split('.');
            this.maxDate = new Date(maxarr[2], maxarr[1] - 1, maxarr[0]);
        }

        this.input.setAttribute('aria-label', this.props.inputLabel);
        this.input.addEventListener('click', (e) => {
            this.hideDialog();
            e.stopPropagation();
            return false;
        });
        this.input.addEventListener('blur', () => {
            this.value = DatePicker.formatDate(this.value);
        });
        this.input.insertAdjacentHTML('afterend', this.getMarkup(this.isMac ? 'mac' : 'win'));

        const counter = 'datovelger_' + this.id;
        this.parent = document.getElementById('counterid');
        this.parent.setAttribute('id', counter);

        const events = 'click keydown'.split(' ');
        for (let i = 0; i < events.length; i++) {
            this.parent.querySelector('.bn_date').addEventListener(events[i], (e) => {
                const isButtonEvent = e.keyCode === this.keys.enter || e.keyCode === this.keys.space;
                if (events[i] === 'keydown' && !isButtonEvent) {
                    return true;
                }
                if (this.calwrap.getAttribute('aria-hidden') === 'true') {
                    this.showDialog();
                } else {
                    this.hideDialog();
                }
                e.stopPropagation();
                return false;
            });
        }

        this.calwrap = this.parent.querySelector('.cal-wrap');
        this.info = this.parent.querySelector('.bn_info');
        this.mnth = this.parent.querySelector('.month');
        this.mnth.setAttribute('id', this.id + '_month');
        this.prev = this.parent.querySelector('.bn_prev');
        this.next = this.parent.querySelector('.bn_next');
        this.bModal = options.modal; // true if datepicker should appear in a modal dialog box.
        this.grid = null;

        this.keys = {
            tab: 9,
            enter: 13,
            esc: 27,
            space: 32,
            pageup: 33,
            pagedown: 34,
            end: 35,
            home: 36,
            left: 37,
            up: 38,
            right: 39,
            down: 40
        };

        this.keyhandlers = {};
        this.keyhandlers[this.keys.tab] = this.tabHandler;
        this.keyhandlers[this.keys.space] = this.updateTargetBox;
        this.keyhandlers[this.keys.enter] = this.updateTargetBox;
        this.keyhandlers[this.keys.esc] = this.dismissDialog;
        this.keyhandlers[this.keys.left] = this.previousday;
        this.keyhandlers[this.keys.right] = this.nextday;
        this.keyhandlers[this.keys.up] = this.previousweek;
        this.keyhandlers[this.keys.down] = this.nextweek;
        this.keyhandlers[this.keys.pageup] = this.previousMonthOrYear;
        this.keyhandlers[this.keys.pagedown] = this.nextMonthOrYear;
        this.keyhandlers[this.keys.home] = this.startOfMonth;
        this.keyhandlers[this.keys.end] = this.endOfMonth;

        this.updateDateValues(new Date());

        DatePicker.instances.push(this);
    }

    updateDateValues(date) {
        this.dateObj = date;
        this.curYear = this.dateObj.getFullYear();
        this.year = this.curYear;
        this.curMonth = this.dateObj.getMonth();
        this.month = this.curMonth;
        this.isCurrentDate = true;
        this.date = this.dateObj.getDate();

        // display the current month
        this.mnth.innerHTML = this.props.monthNames[this.month] + ' ' + this.year;

        // populate the calendar grid
        this.popGrid();

        // update the table's activedescdendant to point to the current day
        this.grid.setAttribute('aria-activedescendant', this.grid.querySelector('.today').getAttribute('class'));

        this.info = this.parent.querySelector('.bn_info');
        this.mnth = this.parent.querySelector('.month');
        this.mnth.setAttribute('id', this.id + '_month');
        this.prev = this.parent.querySelector('.bn_prev');
        this.next = this.parent.querySelector('.bn_next');
        this.bindHandlers();

        // hide dialog if in modal mode
        if (this.bModal === true) {
            this.calwrap.setAttribute('aria-hidden', 'true');
        }
    }

    //
    // popGrid() is a member function to populate the datepicker grid with calendar days representing the current month
    //
    // @return N/A
    //
    popGrid() {
        const numDays = DatePicker.calcNumDays(this.year, this.month);
        const startWeekday = DatePicker.calcStartWeekday(this.year, this.month);
        let weekday;
        let curDay;
        let rowCount = 1;
        let gridCells = '<tr id="' + this.id + '_row1">\n';

        // Insert the leading empty cells
        for (weekday = 0; weekday < startWeekday; weekday++) {
            if (weekday === 0) {
                const curDate = new Date(this.year, this.month, 1);
                gridCells += '<td class="ukenr">' + DatePicker.calcWeekNumber(curDate) + '</td>\n';
            }
            gridCells += '<td class="empty"></td>\n';
        }

        // insert the days of the month.
        for (curDay = 1; curDay <= numDays; curDay++) {
            const curDate = new Date(this.year, this.month, curDay);
            if (weekday === 0) {
                const sunday = new Date(this.year, this.month, (curDay + 6));
                gridCells += '<td class="ukenr">' + DatePicker.calcWeekNumber(sunday) + '</td>\n';
            }

            const classes = [];
            if (curDay === this.date && this.isCurrentDate === true) {
                classes.push('today');
            }
            if (this.minDate !== null) {
                if (curDate.getTime() < this.minDate.getTime()) {
                    classes.push('inactive');
                }
            }
            if (this.maxDate !== null) {
                if (curDate.getTime() > this.maxDate.getTime()) {
                    classes.push('inactive');
                }
            }

            const id = this.id + '_day' + curDay;
            const cssClasses = classes.join(' ');
            const headers = this.id + '_row' + rowCount + ' ' + this.id + '_' + this.props.dayNames[weekday + 1];

            gridCells += `<td id="${id}" class="${cssClasses}" headers="${headers}" role="gridcell" aria-selected="false">${curDay}</td>\n`;

            if (weekday === 6 && curDay < numDays) {
                // This was the last day of the week, close it out
                // and begin a new one
                gridCells += '</tr><tr id="' + this.id + '_row' + (rowCount + 1) + '">\n';
                rowCount++;
                weekday = 0;
            } else {
                weekday++;
            }
        }

        // Insert any trailing empty cells
        for (weekday; weekday < 7; weekday++) {
            gridCells += '<td class="empty"></td>\n';
        }
        gridCells += '</tr>\n';
        if (gridCells === undefined) {
            gridCells = '<tr>\n<td class="errMsg" colspan="8">${this.props.errMsg}</td>\n</tr>'; // eslint-disable-line
        }

        let activedescendant = 'errMsg';
        if (this.grid !== null) {
            activedescendant = this.grid.getAttribute('aria-activedescendant');
        }
        const table = `<table class="cal" role="grid" aria-activedescendant="${activedescendant}" aria-labelledby="${this.id}_month" tabindex="0">
				<thead>
					<tr class="ukedager">
						<th></th>
						<th id="${this.id}_${this.props.dayNames[1]}"><abbr title="${this.props.dayNames[1]}">${this.props.dayNames[1].substring(0, 3)}</abbr></th>
						<th id="${this.id}_${this.props.dayNames[2]}"><abbr title="${this.props.dayNames[2]}">${this.props.dayNames[2].substring(0, 3)}</abbr></th>
						<th id="${this.id}_${this.props.dayNames[3]}"><abbr title="${this.props.dayNames[3]}">${this.props.dayNames[3].substring(0, 3)}</abbr></th>
						<th id="${this.id}_${this.props.dayNames[4]}"><abbr title="${this.props.dayNames[4]}">${this.props.dayNames[4].substring(0, 3)}</abbr></th>
						<th id="${this.id}_${this.props.dayNames[5]}"><abbr title="${this.props.dayNames[5]}">${this.props.dayNames[5].substring(0, 3)}</abbr></th>
						<th id="${this.id}_${this.props.dayNames[6]}"><abbr title="${this.props.dayNames[6]}">${this.props.dayNames[6].substring(0, 3)}</abbr></th>
						<th id="${this.id}_${this.props.dayNames[7]}"><abbr title="${this.props.dayNames[7]}">${this.props.dayNames[7].substring(0, 3)}</abbr></th>
					</tr>
				</thead>
				<tbody>
					${gridCells}
				</tbody>
			</table>`;

        if (this.grid !== null) {
            this.grid.parentNode.removeChild(this.grid);
        }
        this.parent.querySelector('#bn_prev-label').insertAdjacentHTML('beforebegin', table);
        this.grid = this.parent.querySelector('.cal');
        this.grid.focus();
        this.bindHandlers();
    }

    //
    // calcWeekNumber() is a member function to calculate the week number of year
    //
    //
    // @return (integer) week number of year
    //
    static calcWeekNumber(date) {
        const target = new Date(date.valueOf());
        const dayNumber = (date.getUTCDay() + 6) % 7;

        target.setUTCDate(target.getUTCDate() - dayNumber + 3);
        const firstThursday = target.valueOf();
        target.setUTCMonth(0, 1);

        if (target.getUTCDay() !== 4) {
            target.setUTCMonth(0, 1 + ((4 - target.getUTCDay()) + 7) % 7);
        }
        return Math.ceil((firstThursday - target) / (7 * 24 * 3600 * 1000)) + 1;
    }

    //
    // calcNumDays() is a member function to calculate the number of days in a given month
    //
    // @return (integer) number of days
    //
    static calcNumDays(year, month) {
        return 32 - new Date(year, month, 32).getDate();
    }

    //
    // calcstartWeekday() is a member function to calculate the day of the week the first day of a month lands on
    //
    // @return (integer) number representing the day of the week (0=Sunday....6=Saturday)
    //
    static calcStartWeekday(year, month) {
        return (new Date(year, month, 1).getDay() + 6) % 7;
    }

    //
    // showPrevMonth() is a member function to show the previous month
    //
    // @param (offset int) offset may be used to specify an offset for setting
    //                      focus on a day the specified number of days from
    //                      the end of the month.
    // @return N/A
    //
    showPrevMonth(offset) {
        DatePicker.setPrevState();
        if (this.minDate !== null) {
            if (new Date(this.year, this.month, 1).getTime() <= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), 1).getTime()) {
                return true;
            }
        }

        // show the previous month
        if (this.month === 0) {
            this.month = 11;
            this.year--;
        } else {
            this.month--;
        }

        this.isCurrentDate = !(this.month !== this.curMonth || this.year !== this.curYear);

        // populate the calendar grid
        this.popGrid();

        this.mnth.innerHTML = this.props.monthNames[this.month] + ' ' + this.year;

        // if offset was specified, set focus on the last day - specified offset
        if (offset !== undefined) {
            const numDays = DatePicker.calcNumDays(this.year, this.month);
            const dayid = this.id + '_day' + (numDays - offset);
            this.grid.setAttribute('aria-activedescendant', dayid);
            const elm = this.grid.querySelector('#' + dayid);
            if (elm) {
                DatePicker.setGridCellFocus(elm);
                elm.setAttribute('aria-selected', 'true');
            }
        }
        this.handleGridFocus();
        return false;
    }

    //
    // showNextMonth() is a member function to show the next month
    //
    // @param (offset int) offset may be used to specify an offset for setting
    //                      focus on a day the specified number of days from
    //                      the beginning of the month.
    // @return N/A
    //
    showNextMonth(offset) {
        DatePicker.setNextState();
        if (this.maxDate !== null) {
            if (new Date(this.year, this.month, 1).getTime() >= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), 1).getTime()) {
                return true;
            }
        }

        // show the next month
        if (this.month === 11) {
            this.month = 0;
            this.year++;
        } else {
            this.month++;
        }

        this.isCurrentDate = !(this.month !== this.curMonth || this.year !== this.curYear);

        // populate the calendar grid
        this.popGrid();

        this.mnth.innerHTML = this.props.monthNames[this.month] + ' ' + this.year;
        // if offset was specified, set focus on the first day + specified offset
        if (offset !== undefined) {
            const dayid = this.id + '_day' + offset;
            this.grid.setAttribute('aria-activedescendant', dayid);
            const day = this.grid.querySelector('#' + dayid);
            if (day) {
                DatePicker.setGridCellFocus(day);
                day.setAttribute('aria-selected', 'true');
            }
        }
        this.handleGridFocus();
        return false;
    }

    //
    // showPrevYear() is a member function to show the previous year
    //
    // @return N/A
    //
    showPrevYear() {
        DatePicker.setPrevState();
        if (this.minDate !== null) {
            if (this.year <= this.minDate.getFullYear()) {
                return true;
            }
        }

        // decrement the year
        this.year--;
        this.isCurrentDate = !(this.month !== this.curMonth || this.year !== this.curYear);

        // populate the calendar grid
        this.popGrid();
        this.mnth.innerHTML = this.props.monthNames[this.month] + ' ' + this.year;

        return false;
    }

    //
    // showNextYear() is a member function to show the next year
    //
    // @return N/A
    //
    showNextYear() {
        DatePicker.setNextState();
        if (this.maxDate !== null) {
            if (this.year >= this.maxDate.getFullYear()) {
                return true;
            }
        }

        // increment the year
        this.year++;
        this.isCurrentDate = !(this.month !== this.curMonth || this.year !== this.curYear);

        // populate the calendar grid
        this.popGrid();
        this.mnth.innerHTML = this.props.monthNames[this.month] + ' ' + this.year;

        return false;
    }

    static setPrevState() {
        if (this.year === undefined || this.month === undefined) {
            return true;
        }
        const currYearMonth = DatePicker.yearMonthString(new Date(this.year, this.month, 1), -1);
        const minYearMonth = DatePicker.yearMonthString(this.minDate, -1);
        if (currYearMonth === minYearMonth) {
            this.prev.setAttribute('aria-disabled', 'true');
        } else {
            this.prev.removeAttribute('aria-disabled');
        }

        return false;
    }

    static setNextState() {
        if (this.year === undefined || this.month === undefined) {
            return true;
        }
        const currYearMonth = DatePicker.yearMonthString(new Date(this.year, this.month, 1), 1);
        const maxYearMonth = DatePicker.yearMonthString(this.maxDate, 1);
        if (currYearMonth === maxYearMonth) {
            this.next.setAttribute('aria-disabled', 'true');
        } else {
            this.next.removeAttribute('aria-disabled');
        }

        return false;
    }

    static yearMonthString(date, add) {
        let month = (date.setMonth(date.getMonth() + add)).toString();
        if (month.length === 1) {
            month = '0' + month;
        }
        return date.getYear().toString() + month;
    }

    // bindHandlers() is a member function to bind event handlers for the widget
    //
    // @return N/A

    bindHandlers() {
        // bind button handlers
        this.prev.onclick = (e) => {
            return this.handlePrevClick(e);
        };

        this.next.onclick = (e) => {
            return this.handleNextClick(e);
        };

        this.info.onclick = (e) => {
            return this.showInfo(e);
        };

        this.prev.onkeydown = (e) => {
            return this.handlePrevKeyDown(e);
        };

        this.next.onkeydown = (e) => {
            return this.handleNextKeyDown(e);
        };

        this.info.onkeydown = (e) => {
            return this.handleInfoKeyDown(e);
        };

        // bind grid handlers
        this.grid.onkeydown = (e) => {
            return this.handleGridKeyDown(e);
        };

        this.grid.keypress = (e) => {
            return this.handleGridKeyPress(e);
        };

        this.grid.onfocus = (e) => {
            return this.handleGridFocus(e);
        };

        this.grid.onblur = (e) => {
            return this.handleGridBlur(e);
        };

        this.grid.addEventListener('mousedown', () => {
            this.isDragging = 0;
        });
        this.grid.addEventListener('mousemove', () => {
            this.isDragging++;
        });
        this.grid.addEventListener('mouseup', (e) => {
            if (this.isDragging < 10) {
                return this.handleGridClick(e);
            }
            return true;
        });
    }

    // handlePrevClick() is a member function to process click events for the prev month button
    //
    // @input (e obj) e is the event object associated with the event
    //
    // @return (boolean) false if consuming event, true if propagating

    handlePrevClick(e) {
        if (e.ctrlKey) {
            this.showPrevYear();
        } else {
            this.showPrevMonth();
        }

        e.stopPropagation();
        return false;
    }

    //
    // handleNextClick() is a member function to process click events for the next month button
    //
    // @input (e obj) e is the event object associated with the event
    //
    // @return (boolean) false if consuming event, true if propagating
    //
    handleNextClick(e) {
        if (e.ctrlKey) {
            this.showNextYear();
        } else {
            this.showNextMonth();
        }

        e.stopPropagation();
        return false;
    }

    //
    // handlePrevKeyDown() is a member function to process keydown events for the prev month button
    //
    // @input (e obj) e is the event object associated with the event
    //
    // @return (boolean) false if consuming event, true if propagating
    //
    handlePrevKeyDown(e) {
        if (e.altKey) {
            return true;
        }

        switch (e.keyCode) {
            case this.keys.tab: {
                if (this.bModal === false || !e.shiftKey || e.ctrlKey) {
                    return true;
                }

                this.info.focus();
                e.stopPropagation();
                return false;
            }
            case this.keys.enter:
            case this.keys.space: {
                if (e.shiftKey) {
                    return true;
                }

                if (e.ctrlKey) {
                    this.showPrevYear();
                } else {
                    this.showPrevMonth();
                }

                e.stopPropagation();
                return false;
            }
            default: return true;
        }
    }

    //
    // handleNextKeyDown() is a member function to process keydown events for the next month button
    //
    // @input (e obj) e is the event object associated with the event
    //
    // @return (boolean) false if consuming event, true if propagating
    //
    handleNextKeyDown(e) {
        if (e.altKey) {
            return true;
        }

        switch (e.keyCode) {
            case this.keys.enter:
            case this.keys.space: {
                if (e.ctrlKey) {
                    this.showNextYear();
                } else {
                    this.showNextMonth();
                }
                e.stopPropagation();
                return false;
            }
            default: return true;
        }
    }

    handleInfoKeyDown(e) {
        if (e.altKey) {
            return true;
        }

        switch (e.keyCode) {
            case this.keys.enter: {
                this.showInfo(e);
                return false;
            }
            case this.keys.tab: {
                if (e.shiftKey) {
                    this.grid.focus();
                } else {
                    this.prev.focus();
                }
                return false;
            }
            default: return true;
        }
    }

    tabHandler(e) {
        if (this.bModal === true) {
            if (e.shiftKey) {
                this.next.focus();
            } else {
                this.info.focus();
            }
            e.stopPropagation();
        }
    }

    updateTargetBox(e, currDay) {
        if (e.ctrlKey) {
            return true;
        }

        // update the target box
        const cur2DigitDay = currDay.innerHTML;
        this.updateTextfield(cur2DigitDay);
        this.sendChangeEvent();
        this.dismissDialog(e);
        this.validateInput();
        return false;
    }

    dismissDialog(e) {
        // dismiss the dialog box
        this.hideDialog();
        e.stopPropagation();
        return false;
    }

    previousday(e, currDay, days) {
        if (e.ctrlKey || e.shiftKey) {
            return true;
        }
        const dayIndex = DatePicker.index(days, currDay) - 1;
        let prevDay = null;
        if (dayIndex >= 0) {
            prevDay = DatePicker.eq(dayIndex, days);
            if (prevDay) {
                if (currDay) {
                    DatePicker.removeClass(currDay, 'focus');
                    currDay.setAttribute('aria-selected', 'false');
                }
                DatePicker.setGridCellFocus(prevDay);
                prevDay.setAttribute('aria-selected', 'true');
                this.grid.setAttribute('aria-activedescendant', prevDay.getAttribute('id'));
            }
        } else {
            this.showPrevMonth(0);
        }

        e.stopPropagation();
        return false;
    }

    nextday(e, currDay, days) {
        if (e.ctrlKey || e.shiftKey) {
            return true;
        }
        const dayIndex = DatePicker.index(days, currDay) + 1;
        let nextDay = null;
        if (dayIndex < days.length) {
            nextDay = DatePicker.eq(dayIndex, days);
            if (nextDay) {
                if (currDay) {
                    DatePicker.removeClass(currDay, 'focus');
                    currDay.setAttribute('aria-selected', 'false');
                }
                DatePicker.setGridCellFocus(nextDay);
                nextDay.setAttribute('aria-selected', 'true');
                this.grid.setAttribute('aria-activedescendant', nextDay.getAttribute('id'));
            }
        } else {
            // move to the next month
            this.showNextMonth(1);
        }
        e.stopPropagation();
        return false;
    }

    previousweek(e, currDay, days) {
        if (e.ctrlKey || e.shiftKey) {
            return true;
        }

        let dayIndex = DatePicker.index(days, currDay) - 7;
        let prevDay = null;
        if (dayIndex >= 0) {
            prevDay = DatePicker.eq(dayIndex, days);
            if (prevDay) {
                if (currDay) {
                    DatePicker.removeClass(currDay, 'focus');
                    currDay.setAttribute('aria-selected', 'false');
                }
                DatePicker.setGridCellFocus(prevDay);
                prevDay.setAttribute('aria-selected', 'true');
                this.grid.setAttribute('aria-activedescendant', prevDay.getAttribute('id'));
            }
        } else {
            // move to appropriate day in previous month
            dayIndex = 6 - DatePicker.index(days, currDay);
            this.showPrevMonth(dayIndex);
        }
        e.stopPropagation();
        return false;
    }

    nextweek(e, currDay, days) {
        if (e.ctrlKey || e.shiftKey) {
            return true;
        }

        let dayIndex = DatePicker.index(days, currDay) + 7;
        let nextDay = null;
        if (dayIndex < days.length) {
            nextDay = DatePicker.eq(dayIndex, days);
            if (nextDay) {
                if (currDay) {
                    DatePicker.removeClass(currDay, 'focus');
                    currDay.setAttribute('aria-selected', 'false');
                }
                DatePicker.setGridCellFocus(nextDay);
                nextDay.setAttribute('aria-selected', 'true');
                this.grid.setAttribute('aria-activedescendant', nextDay.getAttribute('id'));
            }
        } else {
            // move to appropriate day in next month
            dayIndex = 8 - (days.length - DatePicker.index(days, currDay));
            this.showNextMonth(dayIndex);
        }
        e.stopPropagation();
        return false;
    }

    nextMonthOrYear(e) {
        this.switchMonthOrYear(this.showNextYear, this.showNextMonth)(e);
    }

    previousMonthOrYear(e) {
        this.switchMonthOrYear(this.showPrevYear, this.showPrevMonth)(e);
    }

    switchMonthOrYear(switchYear, switchMonth) {
        return (e) => {
            if (e.altKey) {
                return true;
            }
            if (e.shiftKey) {
                switchYear.call(this);
            } else {
                switchMonth.call(this);
            }
            const currDay = this.grid.querySelector('#' + this.grid.getAttribute('aria-activedescendant'));
            const days = this.grid.querySelectorAll('td:not(.empty):not(.ukenr):not(.inactive)');
            const firstDate = days[0].innerText;
            const firstDayId = this.id + '_day' + firstDate;
            const firstDay = this.grid.querySelector('#' + firstDayId);
            if (firstDay) {
                if (currDay) {
                    DatePicker.removeClass(currDay, 'focus');
                    currDay.setAttribute('aria-selected', 'false');
                }
                DatePicker.setGridCellFocus(firstDay);
                firstDay.setAttribute('aria-selected', 'true');
                this.grid.setAttribute('aria-activedescendant', firstDayId);
            }
            e.stopPropagation();
            return false;
        };
    }

    startOfMonth(e, currDay, days) {
        if (!this.isMac) {
            if (e.ctrlKey || e.shiftKey) {
                return true;
            }
        }
        const firstDate = days[0].innerText;
        const firstDayId = this.id + '_day' + firstDate;
        const firstDay = this.grid.querySelector('#' + firstDayId);
        if (firstDay) {
            if (currDay) {
                DatePicker.removeClass(currDay, 'focus');
                currDay.setAttribute('aria-selected', 'false');
            }
            DatePicker.setGridCellFocus(firstDay);
            firstDay.setAttribute('aria-selected', 'true');
            this.grid.setAttribute('aria-activedescendant', firstDayId);
        }
        e.stopPropagation();
        return false;
    }

    endOfMonth(e, currDay, days) {
        if (!this.isMac) {
            if (e.ctrlKey || e.shiftKey) {
                return true;
            }
        }
        const lastDate = days[days.length - 1].innerText;
        const lastDayId = this.id + '_day' + lastDate;
        const lastDay = this.grid.querySelector('#' + lastDayId);
        if (lastDay) {
            if (currDay) {
                DatePicker.removeClass(currDay, 'focus');
                currDay.setAttribute('aria-selected', 'false');
            }
            DatePicker.setGridCellFocus(lastDay);
            lastDay.setAttribute('aria-selected', 'true');
            this.grid.setAttribute('aria-activedescendant', lastDayId);
        }
        e.stopPropagation();
        return false;
    }

    static passThrough() {
        return true;
    }


    //
    // handleGridKeyDown() is a member function to process keydown events for the datepicker grid
    //
    // @input (e obj) e is the event object associated with the event
    //
    // @return (boolean) false if consuming event, true if propagating
    //
    handleGridKeyDown(e) {
        const currDay = this.grid.querySelector('#' + this.grid.getAttribute('aria-activedescendant'));
        let days;
        if (this.grid) {
            days = this.grid.querySelectorAll('td:not(.empty):not(.ukenr):not(.inactive)');
        }
        if (e.altKey) {
            return true;
        }
        return (this.keyhandlers[e.keyCode] || DatePicker.passThrough).call(this, e, currDay, days);
    }

    //
    // handleGridKeyPress() is a member function to consume keypress events for browsers that
    // use keypress to scroll the screen and manipulate tabs
    //
    // @input (e obj) e is the event object associated with the event
    //
    // @return (boolean) false if consuming event, true if propagating
    //
    handleGridKeyPress(e) {
        if (e.altKey) {
            return true;
        }

        switch (e.keyCode) {
            case this.keys.tab:
            case this.keys.enter:
            case this.keys.space:
            case this.keys.esc:
            case this.keys.left:
            case this.keys.right:
            case this.keys.up:
            case this.keys.down:
            case this.keys.pageup:
            case this.keys.pagedown:
            case this.keys.home:
            case this.keys.end: {
                e.stopPropagation();
                return false;
            }
            default: return true;
        }
    }

    //
    // handleGridClick() is a member function to process mouse click events for the datepicker grid
    //
    // @input (id obj) e is the id of the object triggering the event
    //
    // @input (e obj) e is the event object associated with the event
    //
    // @return (boolean) false if consuming event, true if propagating
    //
    handleGridClick(e) {
        const cell = e.target;
        if (DatePicker.hasClass(cell, 'empty') || DatePicker.hasClass(cell, 'inactive')) {
            return true;
        }
        const focused = this.grid.querySelector('.focus');
        if (focused) {
            DatePicker.removeClass(focused, 'focus');
            focused.setAttribute('aria-selected', 'false');
        }
        DatePicker.setGridCellFocus(cell);
        cell.setAttribute('aria-selected', 'true');
        this.grid.setAttribute('aria-activedescendant', cell.getAttribute('id'));
        const $curDay = this.grid.querySelector('#' + this.grid.getAttribute('aria-activedescendant'));

        // update the target box
        const cur2DigitDay = $curDay.innerHTML;
        this.updateTextfield(cur2DigitDay);
        this.sendChangeEvent();

        // dismiss the dialog box
        this.hideDialog();
        this.validateInput();
        e.stopPropagation();
        return false;
    }

    //
    // handleGridFocus() is a member function to process focus events for the datepicker grid
    //
    // @input (e obj) e is the event object associated with the event
    //
    // @return (boolean) true
    //
    handleGridFocus() {
        const active = this.grid.querySelector('#' + this.grid.getAttribute('aria-activedescendant'));
        if (!active) {
            const today = this.grid.querySelector('.today');
            DatePicker.setGridCellFocus(today);
            today.setAttribute('aria-selected', 'true');
            this.grid.setAttribute('aria-activedescendant', this.id + '_day' + today.innerHTML);
        } else {
            if (active.getAttribute('aria-selected') === 'false') {
                DatePicker.setGridCellFocus(active);
                active.setAttribute('aria-selected', 'true');
            }
        }
        return true;
    }

    static setGridCellFocus(element) {
        if (!DatePicker.hasClass(element, 'inactive')) {
            DatePicker.addClass(element, 'focus');
        }
    }

    //
    // handleGridBlur() is a member function to process blur events for the datepicker grid
    //
    // @input (e obj) e is the event object associated with the event
    //
    // @return (boolean) true
    //
    handleGridBlur() {
        const active = this.grid.querySelector('#' + this.grid.getAttribute('aria-activedescendant'));
        if (active) {
            DatePicker.removeClass(active, 'focus');
            active.setAttribute('aria-selected', 'false');
        }
        return true;
    }

    //
    // showDialog() is a member function to show the datepicker and give it focus. This function is only called if
    // the datepicker is used in modal dialog mode.
    //
    // @return N/A
    //
    showDialog() {
        DatePicker.instances.forEach((picker) => {
            picker.hideDialog(false);
        });

        // Bind an event listener to the document to capture all mouse events to make dialog modal
        this.globalListeners = 'click mousedown mouseup'.split(' ')
            .map((event) => ({ event, listener: this.globalClickHandler.bind(this) }))
            .reduce((acc, { event, listener }) => {
                document.addEventListener(event, listener);
                acc[event] = listener;
                return acc;
            }, {});

        // set active day from input field
        if (this.input.value) {
            const dayarr = this.input.value.split('.');
            if (this.validateInput()) {
                this.updateDateValues(new Date(dayarr[2], dayarr[1] - 1, dayarr[0]));
            }
        }

        // show the dialog
        this.calwrap.setAttribute('aria-hidden', 'false');
        this.grid.focus();
    }

    // hideDialog() is a member function to hide the datepicker and remove focus. This function is only called if
    // the datepicker is used in modal dialog mode.
    //
    // @return N/A

    hideDialog() {
        // unbind the modal event sinks
        Object.entries(this.globalListeners)
            .forEach(([event, listener]) => document.removeEventListener(event, listener));
        this.globalListeners = {};

        // hide the dialog
        this.calwrap.setAttribute('aria-hidden', 'true');
        this.input.focus();
    }

    globalClickHandler(e) {
        const isWithinCalendar = DatePicker.childOf(this.calwrap, e.target);
        if (!isWithinCalendar && this.props.hideOnDocumentClick) {
            this.hideDialog();
        } else {
            DatePicker.ensureFocus(e, this);
        }
    }

    showInfo(e) {
        const helpinfo = this.parent.querySelector('.shortcut_info');
        if (helpinfo.offsetHeight === 0) {
            DatePicker.addClass(e.target, 'opp');
            e.target.style.fontFamily = 'sans-serif';
            e.target.style.fontStyle = 'normal';
            e.target.innerHTML = 'x';
            DatePicker.slideDown(helpinfo, 300, 140);
        } else {
            DatePicker.removeClass(e.target, 'opp');
            e.target.style.fontFamily = 'serif';
            e.target.style.fontStyle = 'italic';
            e.target.innerHTML = 'i';
            helpinfo.removeAttribute('style');
        }
    }

    validateInput() {
        let state = true;
        if (this.useFallback) {
            const value = this.input.value;
            state = DatePicker.validateDateString(value, this.props.inputFormat);
        }
        this.setErrorState(!state);
        return state;
    }

    setErrorState(state) {
        const classList = this.input.parentNode.classList;
        if (state ^ classList.contains('feil')) {
            classList.toggle('feil');
        }
    }

    // `getValue` and `setValue` uses a conditional on `this.useFallback` to determine how dates should be
    // fetched or updated. This is due to differences between the type=text and type=date api.
    getValue() {
        if (this.useFallback) {
            const dayarr = this.input.value.split('.');
            const state = DatePicker.validateDateString(this.input.value, this.props.inputFormat);
            return state ? new Date(dayarr[2], dayarr[1] - 1, dayarr[0]) : null;
        }
        return this.input.valueAsDate;
    }

    setValue(date) {
        if (!date) {
            this.input.value = null;
            return;
        }

        // Is necessary due to wierd behaviour in the Date API where timezones are not correctly handled.
        const safeDate = new Date(date);
        safeDate.setHours(12);

        if (isNaN(safeDate.getHours())) {
            // Invalid format / date. Set value as text
            this.input.value = date;
        } else if (this.useFallback) {
            this.updateDateValues(safeDate);
            this.updateTextfield(safeDate.getDate());
        } else {
            this.input.valueAsDate = safeDate;
        }
        this.sendChangeEvent();
    }

    setMindate(date) {
        this.minDate = date;
    }

    setMaxdate(date) {
        this.maxDate = date;
    }

    updateTextfield(day) {
        this.input.value = (day < 10 ? '0' : '') + day + '.' + (this.month < 9 ? '0' : '') + (this.month + 1) + '.' + this.year;
    }

    sendChangeEvent() {
        let event;
        if (typeof window.Event === 'function') {
            event = new Event('change');
        } else {
            event = document.createEvent('TextEvent');
            event.initEvent('change', false, false);
        }
        this.input.dispatchEvent(event);
    }

    getMarkup(platform) {
        return `<div id="counterid" class="nav-datovelger">
			<div role="button" class="bn_date" title="${this.props.bn_dateTitle}" tabindex="0"></div>
			<div class="cal-wrap" aria-hidden="true">
				<div class="month-wrap">
					<div class="bn_prev" role="button" aria-labelledby="bn_prev-label" tabindex="0"></div>
					<div class="month typo-undertittel" role="heading" aria-live="assertive" aria-atomic="true"></div>
					<div class="bn_next" role="button" aria-labelledby="bn_next-label" tabindex="0"></div>
				</div>
				<div id="bn_prev-label" class="offscreen">${this.props.bn_prevLabel}</div>
				<div id="bn_next-label" class="offscreen">${this.props.bn_nextLabel}</div>
				<div id="bn_info-label" class="offscreen">${this.props.bn_infoLabel}</div>
				<div class="info-wrap">
					<div role="button" aria-labelledby="bn_info-label" class="bn_info" tabindex="0">i</div>
				</div>
				<section class="shortcut_info">
					<h1 class="typo-element">${this.props.keyboard.heading}</h1>
					<table>
						<tr>
							<th>${this.props.keyboard.arrowLeftRight[platform]}</th>
							<td>${this.props.keyboard.arrowLeftRight.text}</td>
						</tr>
						<tr>
							<th>${this.props.keyboard.arrowUpDown[platform]}</th>
							<td>${this.props.keyboard.arrowUpDown.text}</td>
						</tr>
						<tr>
							<th>${this.props.keyboard.pageUpDown[platform]}</th>
							<td>${this.props.keyboard.pageUpDown.text}</td>
						</tr>
						<tr>
							<th>${this.props.keyboard.ctrlPageUpDown[platform]}</th>
							<td>${this.props.keyboard.ctrlPageUpDown.text}</td>
						</tr>
						<tr>
							<th>${this.props.keyboard.homeEnd[platform]}</th>
							<td>${this.props.keyboard.homeEnd.text}</td>
						</tr>
					</table>
				</section>
			</div>
		</div>`;
    }

    static hasClass(elem, classname) {
        if (elem.classList) {
            return elem.classList.contains(classname);
        }
        return elem.getAttribute('class').indexOf(classname) > -1;
    }

    static addClass(elem, classname) {
        if (elem.classList) {
            elem.classList.add(classname);
        } else {
            let classes = elem.getAttribute('class');
            if (classes !== '') {
                classes += ' ';
            }
            classes += classname;
            elem.setAttribute('class', classes);
        }
    }

    static removeClass(elem, classname) {
        if (elem.classList) {
            elem.classList.remove(classname);
        } else {
            let classes = elem.getAttribute('class');
            classes = classes.replace(classname, '');
            elem.setAttribute('class', classes);
        }
    }

    static ensureFocus(e, thisObj) {
        // ensure focus remains on the dialog
        thisObj.grid.focus();
        // Consume all mouse events and do nothing
        e.stopPropagation();
        return false;
    }

    static index(group, element) {
        let n = 0;
        for (let i = 0; i < group.length; i++) {
            if (group[i] === element) {
                return n;
            }
            if (group[i].nodeType === 1) {
                n++;
            }
        }
        return -1;
    }

    static eq(index, element) {
        if (index >= 0 && index < element.length) {
            return element[index];
        }
        return -1;
    }

    static formatDate(input) {
        if (!input) {
            return '';
        }
        const dots = input.trim();
        return dots.replace(/[, :;\-_+*/]/g, '.');
    }

    static slideDown(element, duration, finalheight) {
        const s = element.style;
        s.height = '0px';
        let y = 0;
        const framerate = 48;
        const totalframes = duration / framerate;
        const heightincrement = finalheight / totalframes;
        const oneSecond = 1000;
        const interval = oneSecond / framerate;
        const tween = () => {
            y += heightincrement;
            s.height = y + 'px';
            if (y < finalheight) {
                setTimeout(tween, interval);
            }
        };
        tween();
    }

    static validateDateString(datestring, regex) {
        return regex.test(datestring);
    }

    static childOf(parent, child) {
        let node = child;
        while (node !== null) {
            if (node === parent) {
                return true;
            }
            node = node.parentNode;
        }
        return false;
    }
}

DatePicker.INIT_COUNTER = 0;

export default DatePicker;
