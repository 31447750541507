import {
  ActionType,
  Handling,
  HentLedigeStillingerOKAction,
  HentLedigeStillingerPENDINGAction,
  HentLedigeStillingerFEILETAction,
} from './actions';
import { Dispatch } from './dispatch';
import { fetchThenDispatch } from '../api/fetch-utils';
import { getLedigeStillingerFetch } from '../api/api';
import { DataElement, Status } from '../components/datalaster';

export interface LedigeStillingerState extends DataElement {
  count: number;
}

export const initialState: LedigeStillingerState = {
  count: 0,
  status: Status.IKKE_STARTET,
};

//  Reducer
export default function reducer(
  state: LedigeStillingerState = initialState,
  action: Handling
): LedigeStillingerState {
  switch (action.type) {
      case ActionType.HENT_LEDIGE_STILLINGER_OK:
          return {
              count: action.ledigeStillinger.count,
              status: Status.OK,
          };
      case ActionType.HENT_LEDIGE_STILLINGER_FEILET:
          return {...state, status: Status.FEILET};
      case ActionType.HENT_LEDIGE_STILLINGER_PENDING:
          return {...state, status: Status.PENDING};
      default:
          return state;
  }
}

export function hentLedigeStillinger(): (dispatch: Dispatch) => Promise<void> {
  return fetchThenDispatch<LedigeStillingerState>(() => getLedigeStillingerFetch(), {
      ok: hentLedigeStillingerOK,
      feilet: hentLedigeStillingerFEILET,
      pending: hentLedigeStillingerPENDING,
  });
}

function hentLedigeStillingerOK(status: LedigeStillingerState): HentLedigeStillingerOKAction {
  return {
      ledigeStillinger: status,
      type: ActionType.HENT_LEDIGE_STILLINGER_OK,
  };
}

function hentLedigeStillingerFEILET(): HentLedigeStillingerFEILETAction {
  return {
      type: ActionType.HENT_LEDIGE_STILLINGER_FEILET,
  };
}

function hentLedigeStillingerPENDING(): HentLedigeStillingerPENDINGAction {
  return {
      type: ActionType.HENT_LEDIGE_STILLINGER_PENDING,
  };
}
