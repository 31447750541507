const w = (window as any);

const logEvent = w.frontendlogger ? w.frontendlogger.event : () => { return; };

const domene = 'veiledearbeidssoker';

export interface registrerFraSituasjon {
    fraSituasjon: string;
    erInnlogget: boolean;
    erRegistrert: boolean;
    servicegruppe: string;
    formidlingsgruppe: string;
}

export interface loginnFraSituasjon {
    fraSituasjon: string;
}

export interface visSituasjon {
    visSituasjon: string;
}

export const klikkPaArbeidsplassenLenke = () => {
    logEvent(`${domene}.klikkpaarbeidsplassenlenke`, {}, {});
};

export const loggAntallDagerMellomDagensDatoOgSisteDagMedLonn = (antallDager: number) => {
    logEvent(`${domene}.dagermellomdagensdatoogsistedagmedlonn`, {}, {antallDager});
};

export const loggRegistrerFraSituasjon = (tags: registrerFraSituasjon) => {
    logEvent(`${domene}.registrerfrasituasjon`, {}, tags);
};

export const loggInnFraSituasjon = (tags: loginnFraSituasjon) => {
    logEvent(`${domene}.logginnfrasituasjon`, {}, tags);
};

export const visSituasjon = (tags: visSituasjon) => {
    logEvent(`${domene}.vissituasjon`, {}, tags);
};