import { fetchToJson } from './fetch-utils';
import { InnloggingsstatusState } from '../reducer/innloggingsstatus';
import { OppfolgingState } from '../reducer/oppfolging';
import { FeatureToggles } from '../reducer/feature-toggle';
import { LedigeStillingerState } from '../reducer/ledige-stillinger';
import { arbeidsplassenAntallLenke } from '../utils/lenker';

const getCookie = (name: string) => {
    const re = new RegExp(`${name}=([^;]+)`);
    const match = re.exec(document.cookie);
    return match !== null ? match[1] : '';
};

const requestConfig: RequestInit = {
    credentials: 'same-origin',
    headers: {
        'Content-Type': 'application/json',
        'NAV_CSRF_PROTECTION': getCookie('NAV_CSRF_PROTECTION'),
    }
};

const requestConfigOpen: RequestInit = {
    credentials: 'same-origin',
    headers: {
        'Content-Type': 'application/json'
    }
};

interface ApiProps {
    getOppfolging: string;
    getStatus: string;
    getFeatureToggles: string;
    getLedigeStillinger: string;
}

export const API: ApiProps = {
    getOppfolging: '/veilarbjobbsokerkompetanse/api/oppfolging',
    getStatus: '/veilarbstepup/status',
    getFeatureToggles: '/veiledearbeidssoker/api/feature',
    getLedigeStillinger: arbeidsplassenAntallLenke
};

export function getOppfolgingFetch(): Promise<OppfolgingState> {
    return fetchToJson(API.getOppfolging, requestConfig);
}

export function getInnloggingsstatusFetch(): Promise<InnloggingsstatusState> {
    return fetchToJson(API.getStatus, requestConfig);
}

export const featureQueryParams = (features: string[]): string => {
    const reduceFunc = (acc: string, toggle: string, i: number) => `${acc}${i === 0 ? '?' : '&'}feature=${toggle}`;
    return features.reduce(reduceFunc, '');
};

export function getFeatureTogglesFetch(features: string[]): Promise<FeatureToggles> {
    const unleashUrl = API.getFeatureToggles + featureQueryParams(features);
    return fetchToJson<FeatureToggles>(unleashUrl, requestConfig);
}

export function getLedigeStillingerFetch(): Promise<LedigeStillingerState> {
    return fetchToJson(API.getLedigeStillinger, requestConfigOpen);
}