import * as React from 'react';
import { settParamsContext } from '../hocs/params-context';
import Internlenke, { erInternlenke } from './internlenke';
import { injectIntl } from 'react-intl';
import { cls } from './knapp';
import { hrefResolver, dataAttributes } from '../utils/intl-utils';

const harTomtInnhold = (hrefKey, resolvedHref) => (
    hrefKey === 'void0' ||
    hrefKey === 'void 0' ||
    resolvedHref === 'void0' ||
    resolvedHref === 'void 0'
);

interface Props {
    children?: React.ReactNode;
    className?: string;
    hoved: boolean;
    fare: boolean;
    storrelse: 'liten' | 'stor';
    disabled: boolean;
    spinner: boolean;
    hrefKey?: string;
    intl?: any;
    href?: string;
}

const lenkeknappBase: React.FunctionComponent<Props> = ({ href, hrefKey, children, className, intl, ...props }: Props, { params }) => {
    const resolved = hrefResolver(href, hrefKey, intl, params);
    const intldata = dataAttributes(resolved.key, resolved.reqKey, params);

    if (harTomtInnhold(hrefKey, resolved.href)) {
        return null;
    }

    if (erInternlenke(resolved.href)) {
        return (<Internlenke href={resolved.href} className={cls(className, props)} {...intldata} {...props}>
            {children}
        </Internlenke>);
    }

    return <a href={resolved.href} className={cls(className, props)} {...intldata} {...props}>{children}</a>;
};

const LenkeknappBase = injectIntl(settParamsContext(lenkeknappBase));
export default LenkeknappBase;

export const Lenkeknapp = (props) => <LenkeknappBase {...props} />;
export const LenkeHovedknapp = (props) => <LenkeknappBase className="knapp--hoved" {...props} />;
export const LenkeFareknapp = (props) => <LenkeknappBase className="knapp--fare" {...props} />;
