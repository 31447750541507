import * as React from 'react';
import MiaInfoboks from './mia-infoboks';
import { hentTekster } from '../../utils/intl-utils';
import JobbsokingInfoboks from './jobbsoking-infoboks';
import SituasjonProps from '../../utils/situasjon-props';
import StillingSok from "./stillingsok";

import './jobbsoking-mia.less';

const intlTekster = (intl) => ({
    tittel: intl.tekst('tittel'),
    ingress: intl.tekst('ingress'),
    lenkeTekst: intl.tekst('lenke'),
    lenkeUrl: intl.key('lenke.url')
});

const JobbsokingMia: React.FunctionComponent<SituasjonProps> = (props: SituasjonProps) => {
    const teksterJs = hentTekster(intlTekster, `${props.situasjon}.jobbsoking-bolk.`);
    const teksterMia = hentTekster(intlTekster, `${props.situasjon}.mia-bolk.`);

    return (
        <div className={`jobbsoking jobbsoking--${props.situasjon}`}>
            <div className="blokk-center">
                <StillingSok situasjon={props.situasjon}/>
            </div>
            <div className="jobbsoking-mia">
                <JobbsokingInfoboks tekster={teksterJs} situasjon={props.situasjon}/>
                <MiaInfoboks tekster={teksterMia} situasjon={props.situasjon}/>
            </div>
        </div>
    );
};

export default JobbsokingMia;
