import * as React from 'react';
import { Element, Normaltekst } from 'nav-frontend-typografi';
import { hentTekster } from '../../../utils/intl-utils';

interface RettPaDagpengerInnholdProps {
    situasjon: string;
}

const intlTekster = (intl) => ({
    innhold: intl.html('innhold'),
    relatertTittel: intl.tekst('relatertinnhold.tittel'),
    relatertInnhold: intl.html('relatertinnhold.innhold')
});

const RettPaDagpengerInnhold: React.FunctionComponent<RettPaDagpengerInnholdProps> = (props: RettPaDagpengerInnholdProps) => {
    const tekster = hentTekster(intlTekster, `${props.situasjon}.dagpenger.`);

    return (
        <div className="panel-innhold to-kol">
            <div className="hovedinnhold">
                <Normaltekst className="sjekkboks-liste blokk-l">{tekster.innhold}</Normaltekst>
            </div>
            <div className="relatert-innhold-boks">
                <Element tag="h2" className="panel relatert-innhold">{tekster.relatertTittel}</Element>
                <Normaltekst className="relatert-innhold-lenkeliste">
                    {tekster.relatertInnhold}
                </Normaltekst>
            </div>
        </div>
    );
};

export default RettPaDagpengerInnhold;
