import * as React from 'react';
import moment from 'moment';
import queryString from 'query-string';
import { addLocaleData } from 'react-intl';
import * as nb from 'react-intl/locale-data/nb';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter, Redirect, Route, withRouter } from 'react-router-dom';
import 'moment/locale/nb';
import Routes from './routes';
import tekstJson from './bundle.js';
import create from './reducer/store';
import setupMock from './mock/setup-mock';
import IntlProvider from './intl-provider';
import ParamsContext from './hocs/params-context';
import { erLocalhost, erMock } from './mock/utils';
import IntlDevtools from './components/intl-devtools/intl-devtools';

addLocaleData(nb);

interface ApplicationProps {
    params: any;
    sprak: string;
}

if (erMock() || erLocalhost()) {
    setupMock();
}

const store = create();

const Application: React.FunctionComponent<ApplicationProps> = ({params, sprak}: ApplicationProps) => {
    return (
        <React.Fragment>
            <ParamsContext params={params}>
                {/*
                    Use this line instead to turn on EN locale
                    <IntlProvider defaultLocale="nb" locale={sprak} messages={tekstJson[sprak]}>
                */}
                <IntlProvider locale="nb" tekster={tekstJson['nb']}>
                    <ReduxProvider store={store}>
                        <Routes/>
                    </ReduxProvider>
                </IntlProvider>
            </ParamsContext>
            <IntlDevtools query={params}/>
        </React.Fragment>
    );
};

interface ParamatersProviderProps {
    location: any;
}

const ParametersProvider: React.FunctionComponent<ParamatersProviderProps> = (props: ParamatersProviderProps) => {
    const pathname = props.location.pathname;
    const queryStringParams = queryString.parse(props.location.search);

    const params = {...queryStringParams};
    const sprak = params.sprak;

    if (!sprak || !['nb', 'en'].includes(sprak)) {
        return <Redirect to={`${pathname}?sprak=nb`}/>;
    } else {
        moment.locale(sprak);
        return <Application sprak={sprak} params={params}/>;
    }

};

export default function () {
    return (
        <BrowserRouter>
            <Route path="/" component={withRouter(ParametersProvider)}/>
        </BrowserRouter>
    );
}
