import * as React from 'react';
import { Normaltekst } from 'nav-frontend-typografi';
import TilpassetLenkepanel from '../../components/tilpasset-lenkepanel';
import { hentTekster } from '../../utils/intl-utils';
import RouterProps from '../../utils/router-props';
import { BannerProps } from '../../maler/bannermal';
import SidemalSituasjon from '../../maler/sidemal-situasjon';
import bannerBilde from '../../img/dagpenger.svg';
import './okonomisk-stotte.less';

const intlTekster = (intl) => ({
    bannerTittel: intl.tekst('tittel'),
    bannerIngress: intl.tekst('ingress'),
    innhold: intl.html('innhold'),
    tiltakspenger: intl.tekst('lenke.tiltakspenger'),
    aap: intl.tekst('lenke.aap'),
    kvalifiseringsprogrammet: intl.tekst('lenke.kvalifiseringsprogrammet'),
    oksos: intl.tekst('lenke.oksos'),
    oksosUrl: intl.key('lenke.oksos.url')
});

const navn = 'okonomisk-stotte';

const OkonomiskStotte: React.FunctionComponent<RouterProps> = (props: RouterProps) => {
    const tekster = hentTekster(intlTekster, `${props.match.params.situasjon}.${navn}.`);

    const bannerProps = {
        tittel: tekster.bannerTittel,
        bildeSrc: bannerBilde
    } as BannerProps;

    const rader = [
        <div className="underside-innhold" key={0}>
            <Normaltekst>{tekster.innhold}</Normaltekst>
        </div>,
        <div className="underside-innhold to-kol" key={1}>
            <div className="kol">
                <TilpassetLenkepanel href="../tiltakspenger" data-testid="lenke-tiltakspenger">
                    {tekster.tiltakspenger}
                </TilpassetLenkepanel>
                <TilpassetLenkepanel href="../aap" data-testid="lenke-aap">
                    {tekster.aap}
                </TilpassetLenkepanel>
            </div>
            <div className="kol">
                <TilpassetLenkepanel href="../kvalifiseringsprogrammet" data-testid="lenke-kvalifiseringsprogrammet">
                    {tekster.kvalifiseringsprogrammet}
                </TilpassetLenkepanel>
                <TilpassetLenkepanel hrefKey={tekster.oksosUrl} data-testid="lenke-oksos">
                    {tekster.oksos}
                </TilpassetLenkepanel>
            </div>
        </div>
    ];

    return (
        <SidemalSituasjon bannerProps={bannerProps} className={navn} sideNavn={navn} rader={rader}/>
    );

};

export default OkonomiskStotte;
