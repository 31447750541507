import * as React from 'react';
import { Normaltekst, Systemtittel } from 'nav-frontend-typografi';
import { hentTekster } from '../../../utils/intl-utils';
import SituasjonProps from '../../../utils/situasjon-props';
import { TilpassetLenke } from '../../../components/tilpasset-lenke';

import NedsattFunksjonsEvneBilde from '../../../img/nedsatt-funksjonsevne.svg';
import PsykiskeHelseproblemerBilde from '../../../img/psykiske-helseproblemer.svg';
import './problemer.less';

const nedsattFunksjonsevneIntlTekster = (intl) => ({
    tittel: intl.tekst('tittel'),
    ingress: intl.tekst('ingress'),
    lenke: intl.tekst('lenke'),
    url: intl.key('lenke.url')
});

const psykiskeHelseproblemerIntlTekster = (intl) => ({
    tittel: intl.tekst('tittel'),
    ingress: intl.tekst('ingress'),
    lenke: intl.tekst('lenke'),
    url: intl.key('lenke.url')
});

const ProblemerBolk: React.FunctionComponent<SituasjonProps> = (props: SituasjonProps) => {

    const nedsattFunksjonsevneTekster = hentTekster(nedsattFunksjonsevneIntlTekster,
                                                    `${props.situasjon}.problemer-bolk.nedsatt-funksjonsevne-panel.`);

    const psykiskeHelseproblemerTekster = hentTekster(psykiskeHelseproblemerIntlTekster,
                                                      `${props.situasjon}.problemer-bolk.helseutfordringer-panel.`);

    return (
        <div className="problemer-bolk to-kol">
            <div className="problemer-bolk-panel">
                <div className="problemer-bolk-panel__tekster">
                    <Systemtittel tag="h2" className="problemer-bolk-panel__tittel">{nedsattFunksjonsevneTekster.tittel}</Systemtittel>
                    <Normaltekst className="problemer-bolk-panel__ingress">{nedsattFunksjonsevneTekster.ingress}</Normaltekst>
                    <TilpassetLenke className="lenke" hrefKey={nedsattFunksjonsevneTekster.url}>
                        <Normaltekst tag="span">{nedsattFunksjonsevneTekster.lenke}</Normaltekst>
                    </TilpassetLenke>
                </div>
                <img src={NedsattFunksjonsEvneBilde} className="problemer-bolk-panel__illustrasjon" alt="Nedsatt funksjonsevne illustrasjon"/>
            </div>
            <div className="problemer-bolk-panel">
                <div className="problemer-bolk-panel__tekster">
                    <Systemtittel tag="h2" className="problemer-bolk-panel__tittel">{psykiskeHelseproblemerTekster.tittel}</Systemtittel>
                    <Normaltekst className="problemer-bolk-panel__ingress">{psykiskeHelseproblemerTekster.ingress}</Normaltekst>
                    <TilpassetLenke className="lenke" hrefKey={psykiskeHelseproblemerTekster.url}>
                        <Normaltekst tag="span">{psykiskeHelseproblemerTekster.lenke}</Normaltekst>
                    </TilpassetLenke>
                </div>
                <img src={PsykiskeHelseproblemerBilde} className="problemer-bolk-panel__illustrasjon" alt="Helseutfordringer illustrasjon"/>
            </div>
        </div>
    );
};

export default ProblemerBolk;
