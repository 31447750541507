import * as React from 'react';
import { hentTekster } from '../../../utils/intl-utils';
import SituasjonProps from '../../../utils/situasjon-props';
import { Normaltekst, Systemtittel } from 'nav-frontend-typografi';
import arbeidstreningBilde from '../../../img/arbeidstrening.svg';
import { TilpassetLenke } from '../../../components/tilpasset-lenke';
import './arbeidstrening.less';

const intlTekster = (intl) => ({
    arbeidstreningTittel: intl.tekst('arbeidstrening.tittel'),
    arbeidstreningIngress: intl.tekst('arbeidstrening.ingress'),
    arbeidstreningLenke: intl.tekst('arbeidstrening.lenke'),
    arbeidstreningLenkeUrl: intl.key('arbeidstrening.lenke.url'),
});

const Arbeidstrening: React.FunctionComponent<SituasjonProps> = (props: SituasjonProps) => {
    const tekster = hentTekster(intlTekster, `${props.situasjon}.videreutdanning-bolk.`);

    return (
        <div className="arbeidstrening-inngang">
            <div className="arbeidstrening-inngang__tekster">
                <Systemtittel tag="h2" className="blokk-xxs">{tekster.arbeidstreningTittel}</Systemtittel>
                <Normaltekst className="blokk-s">{tekster.arbeidstreningIngress}</Normaltekst>
                <TilpassetLenke hrefKey={tekster.arbeidstreningLenkeUrl} data-testid="lenke-arbeidstrening">
                    <Normaltekst tag="span">{tekster.arbeidstreningLenke}</Normaltekst>
                </TilpassetLenke>
            </div>
            <div className="arbeidstrening-inngang__bilde">
                <img src={arbeidstreningBilde} alt="Slik får du jobben"/>
            </div>
        </div>
    );
};

export default Arbeidstrening;
