import * as React from 'react';
import { hentTekster } from '../../../utils/intl-utils';
import './belop-innhold.less';

interface BelopInnholdProps {
    situasjon: string;
}

const intlTekster = (intl) => ({
    innhold: intl.html('innhold'),
    ekstra: intl.html('ekstra'),
});

const BelopInnhold: React.FunctionComponent<BelopInnholdProps> = (props: BelopInnholdProps) => {
    const tekster = hentTekster(intlTekster, `${props.situasjon}.dagpenger.belop.`);

    return (
            <div className="panel-innhold dagpenger-innhold">
                {tekster.innhold}
            </div>
    );
};

export default BelopInnhold;
