import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../reducer/dispatch';
import { AppState } from '../reducer/reducer';
import { hentLedigeStillinger, LedigeStillingerState } from '../reducer/ledige-stillinger';
import Datalaster from '../components/datalaster';

interface OwnProps {
    children: React.ReactElement<any>;
}

interface StateProps {
    ledigeStillinger: LedigeStillingerState;
}

interface DispatchProps {
    doHentLedigeStillinger: () => void;
}

type LedigeStillingerProps = OwnProps & StateProps & DispatchProps;

class LedigeStillinger extends React.Component<LedigeStillingerProps> {
    // eslint-disable-next-line
    constructor(props: LedigeStillingerProps) {
        super(props);
    }

    componentDidMount() {
      this.props.doHentLedigeStillinger();
    }

    render() {
        return (
            <Datalaster avhengigheter={[this.props.ledigeStillinger]}>
                {this.props.children}
            </Datalaster>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => ({
    ledigeStillinger: state.ledigeStillinger
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    doHentLedigeStillinger: () => hentLedigeStillinger()(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LedigeStillinger);
