import * as React from 'react';
import { IntlProvider as Provider } from 'react-intl';
import { parse } from 'query-string';

type TekstObjekt = {
    [key: string]: {}
};

interface ProviderProps {
    locale: string;
    tekster: TekstObjekt;
}

const mapTeksterTilNokler = (teksterTilMapping: any) => {
    return Object.keys(teksterTilMapping)
        .map(key => ({key, value: `[${key}]`}))
        .reduce(
            (previous, current) => {
                previous[current.key] = current.value;
                return previous;
            },
            {}
        );
};

const skalViseTekstnokler = (): boolean => {
    const search = parse(window.location.search);
    return search.vistekster === null || search.vistekster === 'true';
};

class IntlProvider extends React.Component<ProviderProps> {
    render() {
        const {children, locale, tekster} = this.props;
        const teksterEllerNokler = skalViseTekstnokler() ? mapTeksterTilNokler(tekster) : tekster;

        return (
            <Provider defaultLocale={locale} locale={locale} messages={teksterEllerNokler || []}>
                {children}
            </Provider>
        );
    }
}

export default IntlProvider;
