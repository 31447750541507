import * as React from 'react';
import SituasjonProps from '../../../utils/situasjon-props';
import { hentTekster } from '../../../utils/intl-utils';
import { Normaltekst, Systemtittel } from 'nav-frontend-typografi';
import { TilpassetLenke } from '../../../components/tilpasset-lenke';

import './mote-nav.less';
import MoteNavBilde from '../../../img/mote-nav.svg';

const intlTekster = (intl) => ({
    tittel: intl.tekst('tittel'),
    innhold: intl.tekst('innhold'),
    lenke: intl.tekst('lenke'),
    lenkeURL: intl.key('lenke.url')
});

const MoteNavBolk: React.FunctionComponent<SituasjonProps> = (props: SituasjonProps) => {

    const tekster = hentTekster(intlTekster, `${props.situasjon}.mote-nav-bolk.`);

    return (
        <div className="mote-nav-bolk">
            <div className="mote-nav-bolk__tekst-innhold">
                <Systemtittel tag="h2" className="innholdstittel">
                    {tekster.tittel}
                </Systemtittel>
                <Normaltekst className="blokk-s">
                    {tekster.innhold}
                </Normaltekst>
                <TilpassetLenke hrefKey={tekster.lenkeURL} className="lenke" data-testid="lenke-jobb-eu-eos">
                    <Normaltekst tag="span">{tekster.lenke}</Normaltekst>
                </TilpassetLenke>
            </div>
            <div className="mote-nav-bolk__illustrasjon">
                <img src={MoteNavBilde} alt="Møte med NAV"/>
            </div>
        </div>
    );
};

export default MoteNavBolk;
