import { InnloggingsstatusState } from './innloggingsstatus';
import { OppfolgingState } from './oppfolging';
import { Action } from 'redux';
import { FeatureToggles } from './feature-toggle';
import { LedigeStillingerState } from './ledige-stillinger';

export enum ActionType {
    HENT_OPPFOLGING_OK = 'HENT_OPPFOLGING_OK',
    HENT_OPPFOLGING_FEILET = 'HENT_OPPFOLGING_FEILET',
    HENT_OPPFOLGING_PENDING = 'HENT_OPPFOLGING_PENDING',
    HENT_INNLOGGINGSSTATUS_OK = 'HENT_INNLOGGINGSSTATUS_OK',
    HENT_INNLOGGINGSSTATUS_FEILET = 'HENT_INNLOGGINGSSTATUS_FEILET',
    HENT_INNLOGGINGSSTATUS_PENDING = 'HENT_INNLOGGINGSSTATUS_PENDING',
    HENT_SYKMELDT_INFO_OK = 'HENT_SYKMELDT_INFO_OK',
    HENT_SYKMELDT_INFO_PENDING = 'HENT_SYKMELDT_INFO_PENDING',
    HENT_SYKMELDT_INFO_FEILET = 'HENT_SYKMELDT_INFO_FEILET',
    HENT_FEATURE_TOGGLES_OK = 'HENT_FEATURE_TOGGLES_OK',
    HENT_FEATURE_TOGGLES_FEILET = 'HENT_FEATURE_TOGGLES_FEILET',
    HENT_FEATURE_TOGGLES_PENDING = 'HENT_FEATURE_TOGGLES_PENDING',
    HENT_LEDIGE_STILLINGER_OK = 'HENT_LEDIGE_STILLINGER_OK',
    HENT_LEDIGE_STILLINGER_FEILET = 'HENT_LEDIGE_STILLINGER_FEILET',
    HENT_LEDIGE_STILLINGER_PENDING = 'HENT_LEDIGE_STILLINGER_PENDING'
}

export interface HentOppfolgingOKAction extends Action {
    type: ActionType.HENT_OPPFOLGING_OK;
    oppfolging: OppfolgingState;
}

export interface HentOppfolgingPENDINGAction extends Action {
    type: ActionType.HENT_OPPFOLGING_PENDING;
}

export interface HentOppfolgingFEILETAction extends Action {
    type: ActionType.HENT_OPPFOLGING_FEILET;
}

export interface HentInnloggingsstatusOKAction extends Action {
    type: ActionType.HENT_INNLOGGINGSSTATUS_OK;
    status: InnloggingsstatusState;
}

export interface HentInnloggingsstatusPENDINGAction extends Action {
    type: ActionType.HENT_INNLOGGINGSSTATUS_PENDING;
}

export interface HentInnloggingsstatusFEILETAction extends Action {
    type: ActionType.HENT_INNLOGGINGSSTATUS_FEILET;
}

export interface HentFeatureTogglesOKAction {
    type: ActionType.HENT_FEATURE_TOGGLES_OK;
    featureToggles: FeatureToggles;
}

export interface HentFeatureTogglesPENDINGAction {
    type: ActionType.HENT_FEATURE_TOGGLES_PENDING;
}

export interface HentFeatureTogglesFEILETAction {
    type: ActionType.HENT_FEATURE_TOGGLES_FEILET;
}

export interface HentLedigeStillingerOKAction {
    type: ActionType.HENT_LEDIGE_STILLINGER_OK;
    ledigeStillinger: LedigeStillingerState;
}

export interface HentLedigeStillingerPENDINGAction {
    type: ActionType.HENT_LEDIGE_STILLINGER_PENDING;
}

export interface HentLedigeStillingerFEILETAction {
    type: ActionType.HENT_LEDIGE_STILLINGER_FEILET;
}

export type Handling =
    | HentInnloggingsstatusOKAction
    | HentInnloggingsstatusFEILETAction
    | HentInnloggingsstatusPENDINGAction
    | HentOppfolgingOKAction
    | HentOppfolgingPENDINGAction
    | HentOppfolgingFEILETAction
    | HentFeatureTogglesOKAction
    | HentFeatureTogglesPENDINGAction
    | HentFeatureTogglesFEILETAction
    | HentLedigeStillingerOKAction
    | HentLedigeStillingerPENDINGAction
    | HentLedigeStillingerFEILETAction;
