import * as React from 'react';
import { defineMessages } from 'react-intl';
import { Panel } from 'nav-frontend-paneler';
import Okonomi from '../../bolker/okonomi/okonomi';
import { BannerProps } from '../../maler/bannermal';
import { intlbuilder } from '../../utils/intl-utils';
import SidemalSituasjon from '../../maler/sidemal-situasjon';
import JobbEuEosBolk from '../../bolker/jobb_eu_eos/jobb-eu-eos';
import JobbsokingMia from '../../bolker/jobbsoking-mia/jobbsoking-mia';
import RegistreringProvider from '../../bolker/registrering/registrering-provider';

const navn = 'permittert';
const intl = intlbuilder(`${navn}.`);
const tekster = defineMessages({
    bannerTittel: intl.tekst('banner.tittel'),
});

const bannerProps = {
    tittel: tekster.bannerTittel
} as BannerProps;

const SituasjonPermittert: React.FunctionComponent = () => {
    const rader = [
        <Okonomi situasjon={navn} key="okonomi" />,
        <JobbsokingMia situasjon={navn} key="jobbsoking" />,
        <JobbEuEosBolk situasjon={navn} key="jobb-eu-eos"/>,
        <div className="registreringspanel-wrapper"><Panel border><RegistreringProvider situasjon={navn} key="registrering"/></Panel></div>
    ];

    return (
        <SidemalSituasjon bannerProps={bannerProps} rader={rader} sideNavn={navn}/>
    );
};

export default SituasjonPermittert;
