import * as React from 'react';
import { Element, Normaltekst } from 'nav-frontend-typografi';
import { hentTekster } from '../../../utils/intl-utils';

const intlTekster = (intl) => ({
    innhold: intl.html('innhold'),
    relatertTittel: intl.tekst('relatertinnhold.tittel'),
    relatertInnhold: intl.html('relatertinnhold.innhold'),
    rettTilAapIngress: intl.html('undertittel.ingress'),
});

const navn = 'aap-rett-pa';

const RettPaAapInnhold = ({situasjon}) => {
    const tekster = hentTekster(intlTekster, `${situasjon}.${navn}.`);

    return (
        <div className="panel-innhold to-kol">
            <div className="hovedinnhold">
                <Normaltekst className="blokk-s">{tekster.rettTilAapIngress}</Normaltekst>
                <div className="sjekkboks-liste">
                    <Normaltekst>{tekster.innhold}</Normaltekst>
                </div>
            </div>
            <div className="relatert-innhold-boks">
                <div className="relatert-innhold panel">
                    <Element tag="h2">{tekster.relatertTittel}</Element>
                </div>
                <Normaltekst className="relatert-innhold-lenkeliste">{tekster.relatertInnhold}</Normaltekst>
            </div>
        </div>
    );
};

export default RettPaAapInnhold;
