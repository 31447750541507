import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '../reducer/dispatch';
import { AppState } from '../reducer/reducer';
import { hentOppfolging, OppfolgingState } from '../reducer/oppfolging';
import Datalaster from '../components/datalaster';

interface OwnProps {
    children: React.ReactElement<any>;
}

interface StateProps {
    oppfolging: OppfolgingState;
    harGyldigOidcInnlogging: boolean;
}

interface DispatchProps {
    doHentOppfolging: () => void;
}

type OppfolgingProps = OwnProps & StateProps & DispatchProps;

class Oppfolging extends React.Component<OppfolgingProps> {
    // eslint-disable-next-line
    constructor(props: OppfolgingProps) {
        super(props);
    }

    componentDidMount() {
        if (this.props.harGyldigOidcInnlogging) {
            this.props.doHentOppfolging();
        }
    }

    render() {
        return (
            <Datalaster avhengigheter={[this.props.oppfolging]} betingelser={[this.props.harGyldigOidcInnlogging]}>
                {this.props.children}
            </Datalaster>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => ({
    oppfolging: state.oppfolging,
    harGyldigOidcInnlogging: state.innloggingsstatus.harGyldigOidcToken === true && state.innloggingsstatus.nivaOidc === 4,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    doHentOppfolging: () => hentOppfolging()(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Oppfolging);
