import * as React from 'react';
import { defineMessages } from 'react-intl';
import { settParamsContext } from '../hocs/params-context';
import { injectIntl, FormattedMessage } from 'react-intl';

function harTomtInnhold(text: string) {
    return text === 'void 0' || text === 'void0';
}

export const hentTekster = (intlTekster, prefix: string) => {
    return defineMessages(intlTekster(intlbuilder(prefix)));
};

export const hierarchicalSearch = (messages, id) => {
    let parentId = id;
    while (parentId && parentId.length > 0) {
        if (messages[parentId]) {
            return parentId;
        }
        parentId = parentId.split('.').slice(1).join('.');
    }
    return id;
};

export const hrefResolver = (href, hrefKey, intl, values = {}) => {

    if (hrefKey) {
        const hierarchicalId = hierarchicalSearch(intl.messages, hrefKey);
        const resolvedHref = intl.formatMessage({ id: hierarchicalId }, values);
        return { href: resolvedHref, key: hierarchicalId, reqKey: hrefKey };
    }

    return { href };
};

export const dataAttributes = (key, requestedKey, params) => {
    const metadata = {
        'data-intl-key': key,
        'data-req-intl-key': requestedKey
    };

    return params.hasOwnProperty('cmskeys') ? metadata : {};
};

function intlTekst({ id, containsHtml = false, intl, values, ...props }: any, { params }) {
    const hierarchicalId = hierarchicalSearch(intl.messages, id);
    const intldata = dataAttributes(hierarchicalId, id, params);
    const arstall = new Date().getFullYear();

    const augmentedValues = {
        ...values,
        arstall,
        arstallMinusEn: arstall - 1,
        arstallMinusTo: arstall - 2,
        arstallMinusTre: arstall - 3
    };

    return (
        <FormattedMessage id={hierarchicalId} {...props} values={augmentedValues}>
            {(text) => {
                if (harTomtInnhold(text)) {
                    return null;
                }
                if (containsHtml) {
                    const html = { __html: text };
                    return <span {...intldata} {...props} dangerouslySetInnerHTML={html} />;
                }
                return <span {...intldata} {...props}>{text}</span>;
            }}
        </FormattedMessage>
    );
}

function intlTekstOrNull({ id, intl, ...rest }) {
    return !!intl.messages[id] ? <IntlTekst id={id} intl={intl} {...rest} /> : null;
}

export const IntlTekst = injectIntl(settParamsContext(intlTekst));

const IntlTekstOrNull = injectIntl(settParamsContext(intlTekstOrNull));

export const intlbuilder = (prefix) => ({
    key(str: string) {
        return `${prefix}${str}`;
    },
    tekst(str: string, props?) {
        return <IntlTekst id={this.key(str)} {...props} />;
    },
    html(str: string, props?) {
        return <IntlTekst id={this.key(str)} containsHtml={true} {...props} />;
    },
    tekstOrNull(str: string, props?) {
        return <IntlTekstOrNull id={this.key(str)} {...props} />;
    }
});
