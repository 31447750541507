import * as React from 'react';
import { Sidetittel, Innholdstittel, Normaltekst } from 'nav-frontend-typografi';
import Knapp from '../components/knapp';
import Panel from 'nav-frontend-paneler';
import { hentTekster } from '../utils/intl-utils';
import RouterProps from '../utils/router-props';

const intlTekster = (intl) => ({
    tittel: intl.tekst('tittel'),
    undertittel: intl.tekst('undertittel'),
    beskrivelse: intl.tekst('beskrivelse'),
    knapp: intl.tekst('knapp')
});

const navn = 'ikke-laget-enda';

const IkkeLagetEnda: React.FunctionComponent<RouterProps> = (props: RouterProps) => {
    const tekster = hentTekster(intlTekster, `${navn}.`);

    return (
        <div className={navn}>
            <div className="rad">
                <div className="limit">
                    <div className="underside-innhold">
                        <Sidetittel className="text-center blokk-m">{tekster.tittel}</Sidetittel>
                        <Panel>
                            <Innholdstittel>{tekster.undertittel}</Innholdstittel>
                            <Normaltekst>{tekster.beskrivelse}</Normaltekst>
                            <Knapp onClick={() => props.history.goBack()}>{tekster.knapp}</Knapp>
                        </Panel>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default IkkeLagetEnda;
