import * as React from 'react';
import DatePicker from './datepicker';

import './datepicker.less';

interface Props {
    children: React.ReactNode;
    value?: string;
    options?: any;
    onChange?: Function;
    onKeyUp?: Function;
    className?: string;
    'data-testid'?: string;
}

class Datepicker extends React.Component<Props> {

    static defaultProps = {
        options: {},
        value: null,
        onChange: () => {},
        onKeyUp: () => {},
    };

    private input: HTMLElement;
    private datepicker: DatePicker;

    componentDidMount() {
        this.input = document.createElement('input');
        this.input.setAttribute('type', 'date');
        this.input.classList.add('datovelger');

        (this.refs.container as any).appendChild(this.input);
        const options = { modal: true, ...this.props.options };

        this.datepicker = new DatePicker(this.input, options);
        this.datepicker.setValue(this.props.value);

        this.input.addEventListener('change', (e) => {
            this.props.onChange(e, this.datepicker);
        });

        this.input.addEventListener('keyup', (e) => {
            this.props.onKeyUp(e, this.datepicker);
        });
    }

    shouldComponentUpdate(nextProps: Props) {
        return this.props.value !== nextProps.value;
    }

    componentWillUnmount() {
        (this.refs.container as any).innerHTML = '';
        this.input = null;
        this.datepicker = null;
    }

    render() {
        return (
            <div ref="container" className={this.props.className} data-testid={this.props['data-testid']}>
                <label>{this.props.children}</label>
            </div>
        );
    }
}

export default Datepicker;
