import * as React from 'react';
import Spinner from 'nav-frontend-spinner';
import './datalaster.less';

export enum Status {
    OK = 'OK',
    FEILET = 'FEILET',
    PENDING = 'PENDING',
    IKKE_STARTET = 'IKKE_STARTET',
}

export interface DataElement {
    status: Status;
}

interface DatalasterProps {
    avhengigheter: DataElement[];
    betingelser?: boolean[];
    children: React.ReactElement<any>;
}

const Datalaster = ({avhengigheter, betingelser, children}: DatalasterProps) => {
    const avhengigheterFiltrert = betingelser ? avhengigheter.filter((a, i) => betingelser[i] === true) : avhengigheter;

    if (avhengigheterFiltrert.length > 0 && avhengigheterFiltrert.some(a => a.status === Status.FEILET)) {
        return (
            <>
                {children}
            </>
        );
    } else if (avhengigheterFiltrert.length > 0 && avhengigheterFiltrert.every(a => a.status === Status.OK)) {
        return children;
    } else if (avhengigheterFiltrert.length === 0) {
        return children;
    }
    return <Spinner type="XXXL" className="sentrert"/>;
};

export default Datalaster;
