import * as React from 'react';
import { Innholdstittel, Normaltekst } from 'nav-frontend-typografi';
import { TilpassetLenke } from '../../components/tilpasset-lenke';
import tiltakbilde from '../../img/tiltak-jobb.svg';
import './din-situasjon-infoboks.less';

interface Props {
    situasjon: string;
    tekster: any;
}

const DinSituasjonInfoboks: React.FunctionComponent<Props> = (props: Props) => {
    return (
        <div className="din-situasjon-infoboks">
            <div className="din-situasjon-infoboks__tekst">
                <Innholdstittel tag="h2">
                    {props.tekster.tittel}
                </Innholdstittel>
                <Normaltekst className="blokk-s">
                    {props.tekster.innhold}
                </Normaltekst>
                <TilpassetLenke hrefKey={props.tekster.infoBoksLenke} className="lenke" data-testid="lenke-tiltak-for-jobb">
                    {props.tekster.infoBoksLenkeTekst}
                </TilpassetLenke>
            </div>
            <img src={tiltakbilde} alt="Tiltak for å komme i jobb" className="din-situasjon-infoboks__illustrasjon"/>
        </div>
    );
};

export default DinSituasjonInfoboks;
