import * as React from 'react';
import { hentTekster } from '../../../utils/intl-utils';
import { Element, Normaltekst } from 'nav-frontend-typografi';

interface SierOppInnholdProps {
    situasjon: string;
}

const intlTekster = (intl) => ({
    relatertTittel: intl.tekst('relatertinnhold.tittel'),
    relatertInnhold: intl.html('relatertinnhold.innhold'),
    rettTilDagpengerIngress: intl.html('undertittel.rett-til-dagpenger.ingress')
});

const SierOppInnhold: React.FunctionComponent<SierOppInnholdProps> = (props: SierOppInnholdProps) => {
    const tekster = hentTekster(intlTekster, `${props.situasjon}.dagpenger.`);

    return (
        <div className="dagpenger-innhold">
            <Element tag="h2">{tekster.rettTilDagpengerIngress}</Element>
            <Normaltekst>{tekster.relatertInnhold}</Normaltekst>
        </div>
    );
};

export default SierOppInnhold;
