import * as React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../reducer/reducer';
import { InnloggingsstatusState } from '../reducer/innloggingsstatus';

export const loginUrl = '/veilarbstepup/oidc?url=/';
export const redirectLoginUrl = '/veilarbstepup/oidc?url=';

interface OwnProps {
    children: React.ReactElement<any>;
}

interface StateProps {
    innloggingsstatus: InnloggingsstatusState;
}

type InnloggingProviderProps = OwnProps & StateProps;

function Innlogging({innloggingsstatus, children}: InnloggingProviderProps) {
    const {erInnlogget, harGyldigOidcToken, nivaOidc} = innloggingsstatus;
    const { location } = window;

    if ((erInnlogget && !harGyldigOidcToken) || (harGyldigOidcToken && nivaOidc < 4)) {
        location.href = loginUrl;
    }
    return children;
}

const mapStateToProps = (state: AppState): StateProps => ({
    innloggingsstatus: state.innloggingsstatus,
});

export default connect(mapStateToProps)(Innlogging);
