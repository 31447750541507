import * as React from 'react';
import TilpassetLenkepanel from '../../components/tilpasset-lenkepanel';
import { hentTekster } from '../../utils/intl-utils';
import OkonomiInfoboks from './okonomi-infoboks';
import SituasjonProps from '../../utils/situasjon-props';
import './okonomi.less';

const intlTekster = (intl) => ({
    tittel: intl.tekst('tittel', {className: 'focusable-heading', tabIndex: '-1'}),
    okonomiOversiktHeader: intl.tekst('lenke.okonomi-oversikt'),
    chatHeader: intl.tekst('lenke.chat'),
    dagpengerAnnenStotte: intl.tekst('lenke.dagpenger-annen-stotte'),
    stonadHeader: intl.tekst('lenke.stonad-header'),
    stonadInnhold: intl.tekst('lenke.stonad-innhold'),
    stonadLenke: intl.tekst('lenke.stonad-lenke'),
    stonadsBoksLenke: intl.key('lenke.stonadboks.url'),
    okonomiOversiktLenke: intl.key('lenke.okonomi-oversikt.url'),
    dagpengerAnnenStotteLenke: intl.key('lenke.annen-stotte.url'),
    chatLenke: intl.key('lenke.chat.url'),
});

const Okonomi: React.FunctionComponent<SituasjonProps> = (props: SituasjonProps) => {
    const tekster = hentTekster(intlTekster, `${props.situasjon}.okonomi-bolk.`);

    return (
        <div className={`okonomi okonomi--${props.situasjon}`}>
            <div className="blokk-center blokk-s">
                <OkonomiInfoboks situasjon={props.situasjon} tekster={tekster}/>
            </div>
            <div className="blokk-center to-kol">
                <TilpassetLenkepanel hrefKey={tekster.okonomiOversiktLenke} className="kol" data-testid="lenke-okonomisk-oversikt">
                    {tekster.okonomiOversiktHeader}
                </TilpassetLenkepanel>
                <TilpassetLenkepanel hrefKey={tekster.dagpengerAnnenStotteLenke} className="kol" data-testid="lenke-annen-stotte">
                    {tekster.dagpengerAnnenStotte}
                </TilpassetLenkepanel>
                <TilpassetLenkepanel hrefKey={tekster.chatLenke} className="kol" data-testid="lenke-chat-om-gjeld">
                    {tekster.chatHeader}
                </TilpassetLenkepanel>
            </div>
        </div>
    );
};

export default Okonomi;
