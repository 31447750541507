import * as React from 'react';
import { defineMessages } from 'react-intl';
import Okonomi from '../../bolker/okonomi/okonomi';
import { BannerProps } from '../../maler/bannermal';
import { intlbuilder } from '../../utils/intl-utils';
import SidemalSituasjon from '../../maler/sidemal-situasjon';
import JobbEuEosBolk from '../../bolker/jobb_eu_eos/jobb-eu-eos';
import JobbsokingMia from '../../bolker/jobbsoking-mia/jobbsoking-mia';

const navn = 'bytte-jobb';
const intl = intlbuilder(`${navn}.`);
const tekster = defineMessages({
    bannerTittel: intl.tekst('banner.tittel'),
});

const bannerProps = {
    tittel: tekster.bannerTittel
} as BannerProps;

const SituasjonBytteJobb: React.FunctionComponent = () => {
    const rader = [
        <JobbsokingMia situasjon={navn} key="jobbsoking"/>,
        <Okonomi situasjon={navn} key="okonomi"/>,
        <JobbEuEosBolk situasjon={navn} key="jobb-eu-eos"/>
    ];
    return (
        <SidemalSituasjon bannerProps={bannerProps} rader={rader} sideNavn={navn}/>
    );
};

export default SituasjonBytteJobb;
