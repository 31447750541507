import * as React from 'react';
import Banner, { BannerProps } from './bannermal';
import classNames from 'classnames';
import DocumentTitle from 'react-document-title';
import { injectIntl } from 'react-intl';
import { hierarchicalSearch } from '../utils/intl-utils';
import './sidemal.less';

interface SidemalProps {
    children: React.ReactNode;
    bannerProps: BannerProps;
    className?: string;
    vinduTittelKey?: string;
    sideNavn: string;
    intl: any;
}

const Sidemal: React.FunctionComponent<SidemalProps> = (props: SidemalProps) => {

    /*
        Dette setter tittelen på browser-vinduet.
        Det gjøres på denne måten siden noen av bannertittlene kan inneholde html,
        som ikke ser bra ut når det blir brukt som vindu tittel.
    */

    let tittelTekstId;
    let tittelTekst;

    if (props.vinduTittelKey) {
        tittelTekstId = props.vinduTittelKey;
    } else if (props.bannerProps.tittel) {
        tittelTekstId = (props.bannerProps.tittel as any).props.id;
    }

    if (tittelTekstId) {
        const hierarchicalTitleKey = hierarchicalSearch(props.intl.messages, tittelTekstId);
        tittelTekst = (hierarchicalTitleKey && props.intl.messages[ hierarchicalTitleKey ]) || 'Veiledning for arbeidssøker';
    } else {
        tittelTekst = 'Veiledning for arbeidssøker';
    }

    const tittel = `${tittelTekst} - www.nav.no`;

    return (
        <DocumentTitle title={tittel}>
            <div className={classNames(props.className, 'side-' + props.sideNavn)} data-testid={'side-' + props.sideNavn}>
                <Banner {...props.bannerProps}/>
                {props.children}
            </div>
        </DocumentTitle>
    );
};

export default injectIntl(Sidemal);
