import * as React from 'react';
import TilpassetLenkepanel from '../../components/tilpasset-lenkepanel';
import { hentTekster } from '../../utils/intl-utils';
import DinSituasjonInfoboks from './din-situasjon-infoboks';
import './din-situasjon.less';
import SituasjonProps from '../../utils/situasjon-props';

const intlTekster = (intl) => ({
    tittel: intl.tekst('tittel'),
    innhold: intl.tekst('innhold'),
    infoBoksLenkeTekst: intl.tekst('lenke.tiltak-for-jobb'),
    infoBoksLenke: intl.key('lenke.tiltak-for-jobb.url'),
    lenketekst: (navn) => intl.tekst(`lenke.${navn}`),
    lenke: (navn) => ({
        hrefKey: intl.key(`lenke.${navn}.url`),
        className: `panel`
    })
});

const DinSituasjon: React.FunctionComponent<SituasjonProps> = (props: SituasjonProps) => {
    const tekster = hentTekster(intlTekster, `${props.situasjon}.din-situasjon-bolk.`);

    const lagLenke = (navn) => (
        <TilpassetLenkepanel {...tekster.lenke(navn)} className="kol" data-testid={`lenke-${navn}`}>
            {tekster.lenketekst(navn)}
        </TilpassetLenkepanel>
    );

    return (
        <div className="din-situasjon">
            <div className="blokk-center blokk-s">
                <DinSituasjonInfoboks tekster={tekster} situasjon={props.situasjon} />
            </div>
            <div className="blokk-center to-kol">
                {lagLenke('nedsatt-funksjonsevne')}
                {lagLenke('psykiske-helseproblemer')}
                {lagLenke('rus')}
                {lagLenke('jobbe-utforetrygdet')}
            </div>
        </div>
    );
};

export default DinSituasjon;
