import * as React from 'react';
import { defineMessages } from 'react-intl';
import { Panel } from 'nav-frontend-paneler';
import SidemalSituasjon from '../../maler/sidemal-situasjon';
import { BannerProps } from '../../maler/bannermal';
import { intlbuilder } from '../../utils/intl-utils';
import JobbEuEosBolk from '../../bolker/jobb_eu_eos/jobb-eu-eos';
import MoteNavBolk from '../../bolker/ung-lite-erfaring/mote-nav/mote-nav';
import VidereutdanningBolk from '../../bolker/ung-lite-erfaring/videreutdannning/videreutdanning';
import ProblemerBolk from '../../bolker/ung-lite-erfaring/problemer/problemer';
import JobblystFacebookBolk from '../../bolker/ung-lite-erfaring/jobblyst-facebook/jobblyst-facebook';
import JobbsokingMia from '../../bolker/jobbsoking-mia/jobbsoking-mia';
import AndreHjelpetjenesterBolk from '../../bolker/ung-lite-erfaring/andre-hjelpetjenester/andre-hjelpetjenester';
import RegistreringProvider from '../../bolker/registrering/registrering-provider';
import './situasjon-ung-lite-erfaring.less';
import Arbeidstrening from '../../bolker/ung-lite-erfaring/arbeidstrening/arbeidstrening';

const navn = 'ung-lite-erfaring';
const intl = intlbuilder(`${navn}.`);
const tekster = defineMessages({
    bannerTittel: intl.tekst('banner.tittel'),
});

const bannerProps = { tittel: tekster.bannerTittel } as BannerProps;

const SituasjonUngLiteErfaring: React.FunctionComponent = () => {
    const rader = [
        <VidereutdanningBolk situasjon={navn} key="videreutdanning" />,
        <Arbeidstrening situasjon={navn} key="arbeidstrening" />,
        <JobbsokingMia situasjon={navn} key="jobbsoking"/>,
        <ProblemerBolk situasjon={navn} key="problemer" />,
        <JobblystFacebookBolk situasjon="jobblyst-facebook" key="jobblyst-facebook" />,
        <JobbEuEosBolk situasjon={navn} key="jobb-eu-eos" />,
        <AndreHjelpetjenesterBolk situasjon={navn} key={navn} />,
        <MoteNavBolk situasjon={navn} key="mote-nav" />,
        <div className="registreringspanel-wrapper"><Panel border><RegistreringProvider situasjon={navn} key="registrering"/></Panel></div>
    ];
    return (
        <SidemalSituasjon bannerProps={bannerProps} rader={rader} sideNavn={navn} />
    );
};

export default SituasjonUngLiteErfaring;
