import * as React from 'react';
import { hentTekster } from '../../../utils/intl-utils';
import { LenkeHovedknapp } from '../../../components/lenkeknapp';
import './soknadsprosess-innhold.less';
import { Normaltekst, Undertittel } from 'nav-frontend-typografi';

interface SoknadsprosessInnholdProps {
    situasjon: string;
}

const intlTekster = (intl) => ({
    innhold: intl.html('innhold', true),
    undertittel: intl.tekst('undertittel'),
    sokknapp: intl.tekst('sokknapp'),
    sokknappLenke: intl.key('sokknapp-lenke')
});

const DagpengerSoknadsprosessInnhold: React.FunctionComponent<SoknadsprosessInnholdProps> = (props: SoknadsprosessInnholdProps) => {
    const tekster = hentTekster(intlTekster, `${props.situasjon}.dagpenger.soknadsprosess.`);

    return (
        <div className="soknadsprosess-innhold dagpenger-innhold sok-om-liste">
            <Undertittel tag="h2" className="blokk-m">{tekster.undertittel}</Undertittel>
            <Normaltekst>{tekster.innhold}</Normaltekst>
            <LenkeHovedknapp hrefKey={tekster.sokknappLenke}>{tekster.sokknapp}</LenkeHovedknapp>
        </div>
    );
};

export default DagpengerSoknadsprosessInnhold;
