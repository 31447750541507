import * as React from 'react';
import { Innholdstittel, Normaltekst, Element } from 'nav-frontend-typografi';
import RouterProps from '../../utils/router-props';
import SidemalSenter from '../../maler/sidemal-senter';
import { hentTekster } from '../../utils/intl-utils';
import { BannerProps } from '../../maler/bannermal';
import { TilpassetLenke } from '../../components/tilpasset-lenke';

import nedsattFunksjonsevneBilde from '../../img/nedsatt-funksjonsevne.svg';
import hjelpemidlerBilde from '../../img/hjelpemidler.svg';
import vanskerBilde from '../../img/vansker.svg';
import './nedsatt-funksjonsevne.less';

const ungLiteErfaringIntlTekster = (intl) => ({
    bannerTittel: intl.tekst('tittel'),
    bannerIngress: intl.tekst('ingress'),
    hjelpemidlerTittel: intl.tekst('hjelpemidler.tittel'),
    hjelpemidlerInnhold: intl.html('hjelpemidler.innhold'),
    hjelpemidlerLenke: intl.tekst('hjelpemidler.lenke'),
    hjelpemidlerLenkeUrl: intl.key('hjelpemidler.lenke.url'),
    vanskerTittel: intl.tekst('vansker.tittel'),
    vanskerInnhold: intl.html('vansker.innhold'),
    vanskerLenke: intl.tekst('vansker.lenke'),
    vanskerLenkeUrl: intl.key('vansker.lenke.url'),
    fotnote: intl.html('fotnote')
});

const utenforArbeidslivetLengeIntlTekster = (intl) => ({
    bannerTittel: intl.tekst('tittel'),
    bannerIngress: intl.tekst('ingress'),
    innhold: intl.html('innhold'),
    relatertTittel: intl.tekst('relatertinnhold.tittel'),
    relatertInnhold: intl.html('relatertinnhold.innhold')
});

interface TeksterProps {
    tekster: any;
}

const navn = 'nedsatt-funksjonsevne';

const NedsattFunksjonsevne: React.FunctionComponent<RouterProps> = (props: RouterProps) => {

    const { situasjon } = props.match.params;
    const intlTekster = (situasjon === 'ung-lite-erfaring') ? ungLiteErfaringIntlTekster : utenforArbeidslivetLengeIntlTekster;
    const tekster = hentTekster(intlTekster, `${situasjon}.${navn}.`);

    const bannerProps = {
        tittel: tekster.bannerTittel,
        ingress: tekster.bannerIngress,
        bildeSrc: nedsattFunksjonsevneBilde
    } as BannerProps;

    return(
        <SidemalSenter bannerProps={bannerProps} className={navn} sideNavn={navn}>
            { (situasjon === 'ung-lite-erfaring') ?
                <UngLiteErfaringNedsattFunksjonsevne tekster={tekster}/>
                :
                <UtenforArbeidslivetLengeNedsattFunksjonsevne tekster={tekster}/>
            }
        </SidemalSenter>
    );

};

const UngLiteErfaringNedsattFunksjonsevne: React.FunctionComponent<TeksterProps> = ({ tekster }) => {
    return (
        <div className="underside-innhold to-blokk">

            <div className="hjelpemidler">
                <div>
                    <div className="hjelpemidler-bilde"><img src={hjelpemidlerBilde} alt=""/></div>
                    <div>
                        <Innholdstittel tag="h2">{tekster.hjelpemidlerTittel}</Innholdstittel>
                        <Normaltekst>{tekster.hjelpemidlerInnhold}</Normaltekst>
                    </div>
                </div>
                <div>
                    <TilpassetLenke hrefKey={tekster.hjelpemidlerLenkeUrl} className="lenke" target="_blank">
                        <Normaltekst tag="span">{tekster.hjelpemidlerLenke}</Normaltekst>
                    </TilpassetLenke>
                </div>
            </div>
            <div className="vansker">
                <div>
                    <div className="vansker-bilde"><img src={vanskerBilde} alt=""/></div>
                    <div>
                        <Innholdstittel tag="h2">{tekster.vanskerTittel}</Innholdstittel>
                        <Normaltekst>{tekster.vanskerInnhold}</Normaltekst>
                    </div>
                </div>
                <div>
                    <TilpassetLenke hrefKey={tekster.vanskerLenkeUrl} className="lenke" target="_blank">
                        <Normaltekst tag="span">{tekster.vanskerLenke}</Normaltekst>
                    </TilpassetLenke>
                </div>
            </div>

        </div>
    );
};

const UtenforArbeidslivetLengeNedsattFunksjonsevne: React.FunctionComponent<TeksterProps> = ({ tekster }) => {
    return (
        <div className="underside-innhold to-kol">
            <div className="hovedinnhold">
                <Normaltekst>{tekster.innhold}</Normaltekst>
            </div>
            <div className="relatert-innhold-boks">
                <div className="panel relatert-innhold">
                    <Element tag="h2">{tekster.relatertTittel}</Element>
                </div>
                <div className="relatert-innhold-lenkeliste">
                    <Normaltekst>{tekster.relatertInnhold}</Normaltekst>
                </div>
            </div>
        </div>
    );
};

export default NedsattFunksjonsevne;
