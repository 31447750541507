import * as React from 'react';
import RouterProps from '../utils/router-props';
import SidemalSenter from '../maler/sidemal-senter';
import { hentTekster } from '../utils/intl-utils';
import { BannerProps } from '../maler/bannermal';
import rettigheterBilde from '../img/rettigheter-og-plikter.svg';
import { Normaltekst } from 'nav-frontend-typografi';

const intlTekster = (intl) => ({
    bannerTittel: intl.tekst('tittel'),
    bannerIngress: intl.tekst('ingress'),
    innhold: intl.html('innhold')
});

const navn = 'rettigheter-og-plikter';

const RettigheterOgPlikter: React.FunctionComponent<RouterProps> = (props: RouterProps) => {
    const tekster = hentTekster(intlTekster, `${props.match.params.situasjon}.${navn}.`);

    const bannerProps = {
        tittel: tekster.bannerTittel,
        ingress: tekster.bannerIngress,
        bildeSrc: rettigheterBilde
    } as BannerProps;

    return (
        <SidemalSenter bannerProps={bannerProps} className={navn} sideNavn={navn}>
            <div className="underside-innhold">
                <Normaltekst>{tekster.innhold}</Normaltekst>
            </div>
        </SidemalSenter>
    );
};

export default RettigheterOgPlikter;
