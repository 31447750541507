import * as React from 'react';
import { settParamsContext } from '../hocs/params-context';
import { Link } from 'react-router-dom';

const omit = (obj, exclude = []) => Object.entries(obj).reduce((acc, [key, value]) => {
    if (!exclude.includes(key)) {
        return { ...acc, [key]: value };
    }
    return acc;
},                                                             {});

const lagHref = (href, context) => ({ pathname: href, query: omit(context.params, ['situasjon']) });

interface Props {
    href: string;
    className?: string;
    children?: React.ReactNode;
}

const Internlenke: React.FunctionComponent<Props> = ({ href, children, className, ...props }: Props, context) => {
    return <Link to={lagHref(href, context)} className={className} {...props}>{children}</Link>;
};

export default settParamsContext(Internlenke);

export const erInternlenke = (href) => !href.startsWith('http') && !href.startsWith('https');
