import * as React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';

const styleCache = {};
const zIndex = (elementConfig, index, value) => () => {
    const style = elementConfig.element.style;
    if (!styleCache.hasOwnProperty(index)) {
        styleCache[index] = {
            zIndex: style.zIndex,
            backgroundColor: style.backgroundColor,
            display: style.display
        };
    }
    if (value && value > 0) {
        if (style.position.length === 0) {
            style.position = 'relative';
        }
        style.zIndex = value;
        style.backgroundColor = 'rgba(255, 150, 150, 0.75)';
        style.display = 'inline-block';
    } else {
        style.zIndex = styleCache[index].zIndex;
        style.backgroundColor = styleCache[index].backgroundColor;
    }
};

const matches = (elm, selector) => {
    const result = Array.from((elm.document || elm.ownerDocument).querySelectorAll(selector));
    return result.findIndex((item) => item === elm) >= 0;
};

const toElementConfig = (list, element) => {
    const {children, dataset: {intlKey, reqIntlKey}} = element;
    if (children.length > 0) {
        return list.concat([{element, intlKey, reqIntlKey, border: true}, {element, intlKey, reqIntlKey}]);
    }

    return list.concat([{element, intlKey, reqIntlKey}]);
};
const renderBorderElement = (elementConfig, index, isMatch) => {
    const position = elementConfig.element.getBoundingClientRect();
    const scrolltop = (document.body.scrollTop || document.documentElement.scrollTop);

    const style = {
        top: `${position.top + scrolltop}px`,
        left: `${position.left}px`,
        width: `${position.width}px`,
        height: `${position.height}px`
    };
    const cls = ['intl-border', isMatch ? 'fullmatch' : ''].join(' ');

    return <div className={cls} key={`intl-border-${index}`} style={style}/>;
};
const toReactElements = (elementConfig, index) => {
    const isMatch = elementConfig.intlKey === elementConfig.reqIntlKey;

    if (elementConfig.hasOwnProperty('border')) {
        return renderBorderElement(elementConfig, index, isMatch);
    }

    const matchMessage = !isMatch ?
        (<div><i>Mer spesifikk:</i> {elementConfig.reqIntlKey}</div>) :
        null;

    const position = elementConfig.element.getBoundingClientRect();
    const scrolltop = (document.body.scrollTop || document.documentElement.scrollTop);

    const style = {
        top: `${position.bottom + 5 + scrolltop}px`,
        left: `${position.left}px`
    };

    const cls = ['intl-div', isMatch ? 'fullmatch' : ''].join(' ');

    return (
        <div
            className={cls}
            style={style}
            key={`intl-div-${index}`}
            tabIndex={-1}
            onFocus={zIndex(elementConfig, index, 1000)}
            onBlur={zIndex(elementConfig, index, 0)}
        >
            <span>
                {elementConfig.intlKey}
                <br/>
                <span className="intl-message">{matchMessage}</span>
            </span>
        </div>
    );
};

interface IntlDevtoolsProps {
    query: any;
}

class IntlDevtools extends React.Component<IntlDevtoolsProps> {
    // eslint-disable-next-line
    constructor(props: IntlDevtoolsProps) {
        super(props);
    }

    componentDidMount() {
        // wait untis page is fully loaded
        window.onload = this.renderInternal.bind(this);
    }

    componentDidUpdate() {
        this.renderInternal();
    }

    componentWillUnmount() {
        unmountComponentAtNode(this.refs.container);
    }

    renderInternal = () => {
        const style = (i) => (
            {
                top: `${250 + i * 100}px`,
                left: '20px'
            }
        );

        const howTo = (
            <div className="howto">
                <div
                    className="intl-div"
                    style={style(1)}
                >
                <span>
                    Felles key
                    <br/>
                    <span className="intl-message">
                        Keys i denne fargen kan være i bruk på flere situasjoner.
                        For å overskrive med en key som kun gjelder denne situasjonen,
                        kan du legge til en ny ledetekst med keyen spesifisert under
                        <i>Mer spesifikk</i> for den aktuelle ledetekst-boksen.
                    </span>
                </span>
                </div>
                <div
                    className="intl-div fullmatch"
                    style={style(0)}
                >
                <span>
                    Situasjonsspesifikk key
                    <br/>
                    <span className="intl-message">
                        Keys i denne fargen er kun i bruk i denne situasjonen.
                    </span>
                </span>
                </div>
            </div>);

        if (this.props.query.hasOwnProperty('cmsoverlay')) {
            const filter = this.props.query.cmsoverlay || '*';

            const elements = Array.from(document.querySelectorAll('[data-intl-key]'))
                .filter((element) => matches(element, filter))
                .reduce(toElementConfig, [])
                .map(toReactElements);

            render(<div>{howTo}{elements}</div>, this.refs.container);
        }

    }

    render() {
        return (
            <div ref="container" className="intl-devtools"/>
        );
    }
}

export default IntlDevtools;
