import FetchMock, { Middleware, MiddlewareUtils } from 'yet-another-fetch-mock';
import { API } from '../api/api';
import { toggleRegistreringTekst } from '../reducer/feature-toggle';

export default () => {

    const loggingMiddleware: Middleware = (request, response) => {
        console.log(request.url, response);
        return response;
    };

    const fetchMock = FetchMock.configure({
        enableFallback: true, // default: true
        middleware: MiddlewareUtils.combine(
            MiddlewareUtils.delayMiddleware(200),
            MiddlewareUtils.failurerateMiddleware(0.01),
            loggingMiddleware
        )
    });

    console.log('### FULL MOCK AKTIVERT! ###');

    fetchMock.get(API.getOppfolging, {
        underOppfolging: false,
        servicegruppe: '',
        formidlingsgruppe: ''
    });

    fetchMock.get(API.getStatus, {
        erInnlogget: false, // harGyldigOpenAMToken
        harGyldigOidcToken: true,
        nivaOidc: 4
    });

    fetchMock.get(API.getFeatureToggles, {
        [toggleRegistreringTekst]: true,
    });

    fetchMock.get(API.getLedigeStillinger, {
        count: 23657,
    });
};
