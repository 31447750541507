import * as React from 'react';
import { connect } from 'react-redux';
import { Innholdstittel, Normaltekst } from 'nav-frontend-typografi';
import { AppState } from '../../reducer/reducer';
import { hentTekster } from '../../utils/intl-utils';
import { redirectLoginUrl } from '../../providers/innlogging';
import SituasjonProps from '../../utils/situasjon-props';
import { OppfolgingState } from '../../reducer/oppfolging';
import { InnloggingsstatusState } from '../../reducer/innloggingsstatus';
import { TilpassetLenkepanel } from '../../components/tilpasset-lenkepanel';
import { loggRegistrerFraSituasjon, loggInnFraSituasjon, visSituasjon } from '../../metrics'

import './registrering.less';

const intlTekster = (intl) => ({
    alleredeRegLenke: intl.tekst('allerede-registrert-lenke'),
    alleredeRegTekst: intl.tekst('allerede-registrert-tekst'),

    ikkeRegistrertTittel: intl.tekst('ikke-registrert-tittel'),
    ikkeRegistrertBrodtekst: intl.tekst('ikke-registrert-brodtekst'),

    registrertTittel: intl.tekst('registrert-tittel'),
    registrertBrodtekst: intl.tekst('registrert-brodtekst'),

    dittNavLenke: intl.tekst('ditt-nav-lenke'),
    registrerDegLenke: intl.tekst('registrer-deg-lenke'),
    registreringUrl: intl.key('registrering-arbeidssoker-url'),
    registrertPanelUrl: intl.key('veien-til-arbeid-url'),

    ungIkkeRegistrertBrodtekst: intl.tekst('ung-lite-erfaring.ikke-registrert.brodtekst'),
    ungRegistrertBrodtekst: intl.tekst('ung-lite-erfaring.registrert.brodtekst'),

    utenforIkkeRegistrertBrodtekst: intl.tekst('utenfor-arbeidslivet-lenge.ikke-registrert.brodtekst'),
    utenforRegistrertBrodtekst: intl.tekst('utenfor-arbeidslivet-lenge.registrert.brodtekst'),

    situasjonsvelgerTittel: intl.tekst('situasjonsvelger-tittel'),
    situasjonsvelgerBeskrivelse: intl.html('situasjonsvelger-beskrivelse')
});

interface StateProps {
    oppfolgingState: OppfolgingState;
    innloggingsState: InnloggingsstatusState;
}

type RegistreringProps = StateProps & SituasjonProps;

const Registrering: React.FunctionComponent<RegistreringProps> = (props: RegistreringProps) => {

    const erInnlogget = props.innloggingsState.harGyldigOidcToken && props.innloggingsState.nivaOidc === 4;
    const erRegistrert = props.oppfolgingState.underOppfolging;
    const servicegruppe = props.oppfolgingState.servicegruppe;
    const formidlingsgruppe = props.oppfolgingState.formidlingsgruppe;
    const { location } = window;
    const loginUrl = `${redirectLoginUrl}${location.href.split('?')[0]}`;
    const fraSituasjon = props.situasjon;

    const tekster = hentTekster(intlTekster, '');
    const testId = 'lenke-registrering-arbeidssoker';

    const loggFraSituasjon = () => {
        const tags = {
            fraSituasjon,
            erInnlogget,
            erRegistrert,
            servicegruppe,
            formidlingsgruppe
        };
        loggRegistrerFraSituasjon(tags);
    };

    const loggInn = () => {
        const tags = {
            fraSituasjon
        };
        loggInnFraSituasjon(tags);
    };

    let tittel = tekster.ikkeRegistrertTittel;
    let brodtekst = tekster.ikkeRegistrertBrodtekst;
    let paneltekst = tekster.registrerDegLenke;
    let panelurl = tekster.registreringUrl;

    if (erRegistrert) {
        tittel = tekster.registrertTittel;
        brodtekst = tekster.registrertBrodtekst;
        panelurl = tekster.registrertPanelUrl;
        paneltekst = tekster.dittNavLenke;
    }

    if (props.situasjon === 'ung-lite-erfaring') {
        if (erRegistrert) {
            brodtekst = tekster.ungRegistrertBrodtekst;
        } else {
            brodtekst = tekster.ungIkkeRegistrertBrodtekst;
        }
    } else if (props.situasjon === 'utenfor-arbeidslivet-lenge') {
        if (erRegistrert) {
            brodtekst = tekster.utenforRegistrertBrodtekst;
        } else {
            brodtekst = tekster.utenforIkkeRegistrertBrodtekst;
        }
    } else if (fraSituasjon === 'situasjonsvelger') {
        tittel = tekster.situasjonsvelgerTittel;
        brodtekst = tekster.situasjonsvelgerBeskrivelse;
    }

    visSituasjon({ visSituasjon: fraSituasjon})
    return (

            <div className={`registrering registrering--${props.situasjon}`}>
                <Innholdstittel className="registrering__innholdstittel">
                    { tittel }
                </Innholdstittel>
                <div className="blokk-center">
                    <Normaltekst className="registrering__brodtekst">
                        { brodtekst }
                    </Normaltekst>
                    <TilpassetLenkepanel hrefKey={panelurl}
                        className="registrering__lenkepanel blokk-center"
                        data-testid={testId}
                        onClick={ loggFraSituasjon }
                    >
                        {paneltekst}
                    </TilpassetLenkepanel>
                    {!erInnlogget ?
                        <div className="uinnlogget">
                            <Normaltekst tag="span">
                                {tekster.alleredeRegTekst} {' '}
                                <a href={loginUrl} onClick={ loggInn } className="lenke">{tekster.alleredeRegLenke}</a>
                            </Normaltekst>
                        </div> : null
                    }
                </div>
            </div>

    );
};

const mapStateToProps = (state: AppState): StateProps => ({
    innloggingsState: state.innloggingsstatus,
    oppfolgingState: state.oppfolging,
});

export default connect(mapStateToProps)(Registrering);
