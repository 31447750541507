import * as React from 'react';
import { Route, Switch } from 'react-router';
import IkkeLagetEnda from './sider/ikke-laget-enda';
import SituasjonsVelgerPage from './sider/situasjon/situasjonsvelger';
import SituasjonBytteJobb from './sider/situasjon/situasjon-bytte-jobb';
import SituasjonMistetJobben from './sider/situasjon/situasjon-mistet-jobben';
import SituasjonPermittert from './sider/situasjon/situasjon-permittert';
import SituasjonUngLiteErfaring from './sider/situasjon/situasjon-ung-lite-erfaring';
import SituasjonUtenforArbeidslivetLenge from './sider/situasjon/situasjon-utenfor-arbeidslivet-lenge';
import Dagpenger from './sider/dagpenger/dagpenger';
import Tiltakspenger from './sider/okonomisk-stotte/tiltakspenger';
import Arbeidsavklaringspenger from './sider/okonomisk-stotte/aap/aap';
import AndreYtelser from './sider/andre-ytelser';
import Kvalifiseringsprogrammet from './sider/okonomisk-stotte/kvalifiseringsprogrammet';
import DagpengerAnnenstotte from './sider/dagpenger-annenstotte/dagpenger-annenstotte';
import OkonomiOversikt from './sider/okonomi-oversikt/okonomi-oversikt';
import Arbeidstrening from './sider/arbeidstrening/arbeidstrening';
import ChatOmGjeld from './sider/chat-om-gjeld';
import RettigheterOgPlikter from './sider/rettigheter-og-plikter';
import JobbsokingFlereKanaler from './sider/jobbsoking-flere-kanaler';
import OkonomiskStotte from './sider/okonomisk-stotte/okonomisk-stotte';
import TiltakForJobb from './sider/tiltak-for-jobb';
import NedsattFunksjonsevne from './sider/nedsatt-funksjonsevne/nedsatt-funksjonsevne';
import PsykiskeHelseproblemer from './sider/psykiske-helseproblemer';
import Rus from './sider/rus';
import Okonomisksosialhjelp from './sider/okonomisksosialhjelp';
import MoteMedNav from './sider/mote-med-nav/mote-med-nav';
import Helseutfordringer from './sider/helseutfordringer';
import AndreHjelpetjenester from './sider/andre-hjelpetjenester/andre-hjelpetjenester';
import TrengerBosted from './sider/trenger-bosted';
import SporsmalOmOkonomi from './sider/sporsmal-om-okonomi';

class Routes extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <Switch>
                {/* Forside */}
                <Route exact={true} path="/" component={SituasjonsVelgerPage}/>

                {/* Hovedsider */}
                <Route exact={true} path="/bytte-jobb" component={() => <SituasjonBytteJobb/>}/>
                <Route exact={true} path="/mistet-jobben" component={() => <SituasjonMistetJobben/>}/>
                <Route exact={true} path="/permittert" component={() => <SituasjonPermittert/>}/>
                <Route exact={true} path="/ung-lite-erfaring" component={() => <SituasjonUngLiteErfaring/>}/>
                <Route exact={true} path="/utenfor-arbeidslivet-lenge" component={() => <SituasjonUtenforArbeidslivetLenge/>}/>

                {/* Skal fjernes når google har indekserert på den nye url /arbeidssokerregistrering/start*/}
                <Route path="/:situasjon/registrering-arbeidssoker" render={() => {
                    window.location.href = '/arbeidssokerregistrering/start';
                    return null;
                }}/>
                <Route path="/:situasjon/registrering" render={() => {
                    window.location.href = '/arbeidssokerregistrering/start';
                    return null;
                }}/>
                {/* Skal fjernes når google har indekserert på den nye url /arbeidssokerregistrering/start*/}

                <Route path="/:situasjon/dagpenger" component={Dagpenger}/>
                <Route path="/:situasjon/tiltakspenger" component={Tiltakspenger}/>
                <Route path="/:situasjon/aap" component={Arbeidsavklaringspenger}/>
                <Route path="/:situasjon/andre-ytelser" component={AndreYtelser}/>
                <Route path="/:situasjon/kvalifiseringsprogrammet" component={Kvalifiseringsprogrammet}/>
                <Route path="/:situasjon/okonomi-annenstotte" component={DagpengerAnnenstotte}/>
                <Route path="/:situasjon/okonomi-oversikt" component={OkonomiOversikt}/>
                <Route path="/:situasjon/800gjeld" component={ChatOmGjeld}/>
                <Route path="/:situasjon/rettigheter-og-plikter" component={RettigheterOgPlikter}/>
                <Route path="/:situasjon/andre-hjelpetjenester-utenfor-nav" component={AndreHjelpetjenester}/>
                <Route path="/:situasjon/mote-med-nav" component={MoteMedNav}/>
                <Route path="/:situasjon/jobbsoking-i-flere-kanaler" component={JobbsokingFlereKanaler}/>
                <Route path="/:situasjon/arbeidstrening" component={Arbeidstrening}/>
                <Route path="/:situasjon/okonomisk-stotte" component={OkonomiskStotte}/>
                <Route path="/:situasjon/tiltak-for-jobb" component={TiltakForJobb}/>
                <Route path="/:situasjon/nedsatt-funksjonsevne" component={NedsattFunksjonsevne}/>
                <Route path="/:situasjon/psykiske-helseproblemer" component={PsykiskeHelseproblemer}/>
                <Route path="/:situasjon/helseutfordringer" component={Helseutfordringer}/>
                <Route path="/:situasjon/rus" component={Rus}/>
                <Route path="/:situasjon/okonomisksosialhjelp" component={Okonomisksosialhjelp}/>
                <Route path="/:situasjon/trenger-bosted" component={TrengerBosted}/>
                <Route path="/:situasjon/sporsmal-om-okonomi" component={SporsmalOmOkonomi}/>

                {/* 404 */}
                <Route component={IkkeLagetEnda}/>

            </Switch>
        );
    }
}

export default Routes;
