import * as React from 'react';
import classNames from 'classnames';

export const cls = (className, props) => classNames('knapp', className, {
    'knapp--hoved': props.hoved,
    'knapp--fare': props.fare,
    'knapp--liten': props.storrelse === 'liten',
    'knapp--stor': props.storrelse === 'stor',
    'er-inaktiv': props.disabled,
    'knapp-spinner': props.spinner
});

interface Props {
    children?: React.ReactNode;
    className?: string;
    hoved?: boolean;
    fare?: boolean;
    storrelse?: 'liten' | 'stor';
    disabled?: boolean;
    spinner?: boolean;
    onClick?: () => void;
}

const KnappBase: React.FunctionComponent<Props> = ({children, className, ...props}: Props) => {
    return (
        <button className={cls(className, props)} {...props}>{children}</button>
    );
};

export default KnappBase;

export const Knapp = (props) => <KnappBase {...props} />;
export const Hovedknapp = (props) => <KnappBase hoved={true} {...props} />;
export const Fareknapp = (props) => <KnappBase fare={true} {...props} />;
